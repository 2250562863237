import * as React from "react";
import { customToken } from "./customToken";
import { useState, useEffect, useRef, useMemo } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { IoClose, IoCloseSharp } from "react-icons/io5";
import axios from "axios";
import { process } from "@progress/kendo-data-query";
import { Button, Card } from "@mui/material";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import {
  FaCartPlus,
  FaCheck,
  FaFileExcel,
  FaFilePdf,
  FaMinus,
  FaMinusCircle,
  FaPlus,
  FaPlusCircle,
  FaWindowClose,
} from "react-icons/fa";
import { IoMdCheckbox, IoMdClose } from "react-icons/io";
import Enumerable from "linq";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import { Pager } from "@progress/kendo-react-data-tools";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import { Button as ButtonKendo } from "@progress/kendo-react-buttons";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { SiTruenas } from "react-icons/si";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from "@progress/kendo-react-excel-export";
import moment from "moment";
import { Modal } from "antd";
import { NotificationAddTwoTone } from "@mui/icons-material";
import { NotificationGroup } from "@progress/kendo-react-notification";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";

const aggregates = [
  // {
  //   field: "p1CashBlncAmt",
  //   aggregate: "sum",
  // },
];
const DATA_ITEM_KEY = "seqno";
const SELECTED_FIELD = "selected";
const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const ErrorLogDataGrid = ({ data }) => {
  var tempToken = JSON.parse(localStorage.getItem("token"));

  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState("");

  const [locked, setLocked] = React.useState(false);

  const [expandedState, setExpandedState] = React.useState(true);

  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    for (var i = 0; i < newColumnsState.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        if (newColumnsState[i].field === columns[j].field)
          newColumnsState[i].headerClassName = columns[j].headerClassName;
      }
    }
    return newColumnsState;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const initialGroup = [
    {
      field: "cnsldtnNmFilter",
    },
  ];
  const defaultColumns = [
    // {
    //   title: "Consolidation",
    //   field: "cnsldtnNmFilter",
    //   minWidth: 225,
    //   show: true,
    //   locked: true,
    // },
    {
      title: "Error Date",
      field: "errorDt",
      minWidth: "300px",
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Error Message",
      field: "errorMsg",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
    },

    {
      title: "Error Source",
      field: "errorSource",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
    },
    {
      title: "Stack Trace ",
      field: "stackTrace",
      minWidth: "auto",
      show: true,
      filter: "text",
      locked: false,
    },
  ];
  console.log("tab:", localStorage.getItem("activeTab"));
  const themes = useSelector(selectTheme);
  const CustomGroupHeader = (props) => {
    return `${props.value}`;
  };
  let loadedColumns = localStorage.getItem("gridColumns");
  //const GridColumns = defaultColumns;
  //const GridColumns = flag ? defaultColumns: defaultColumns;
  const GridColumns = defaultColumns;
  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: 2000,
    skip: 0,
    sort: [
      {
        field: "errorDt",
        dir: "desc",
      },
    ],
  });
  let initialStateExport = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    //group: [{ field: 'pmrDesc' }]
  });

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );
  // useEffect(() => {

  //   setResult(processWithGroups(data, initialState.dataState));
  // });
  const [resultExport, setResultExport] = React.useState(
    processWithGroups(data, initialStateExport.dataState)
  );

  const handleCommentClik = (props) => {
    setDetails(props.currentTarget.innerText);
    setShow(true);
  };
  const CustomCell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    // return (
    //   <td onClick={handleCommentClik}
    //   title= {value}
    //     {...props.tdProps}
    //     colSpan={1}
    //     style={{
    //       color: props.color,
    //       whiteSpace:props.whiteSpace,
    //       textDecoration:props.textDecoration,
    //       cursor:props.cursor
    //     }}
    //   >
    //     {value}
    //   </td>
    // );
    return (
      <td
        onClick={handleCommentClik}
        title={value}
        {...props.tdProps}
        colSpan={1}
        style={{
          color: props.color,
          whiteSpace: props.whiteSpace,
          textDecoration: props.textDecoration,
          cursor: props.cursor,
        }}
      >
        {value}
      </td>
    );
  };
  const colors = themes === "dark" ? "#4ad8ff" : "#004cff";
  const MyCustomCell = (props) => (
    <CustomCell
      {...props}
      color={colors}
      whiteSpace={"nowrap"}
      textDecoration={"underline"}
      cursor={"pointer"}
    />
  );

  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(GridColumns);
  defaultColumns[3].className = "customCell";
  defaultColumns[3].cell = MyCustomCell;

  const [asOfDate, setasOfDate] = useState(0);
  let pageSize = 10;
  const [page, setPage] = React.useState({
    skip: 0,
    take: Number.MAX_VALUE,
  });
  const dataExport = process(data, {
    group: initialGroup,
  }).data;
  let _pdfExport;

  const dataStateChange = (event) => {
    if (event.dataState.filter !== undefined) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }

    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumns.length; j++) {
        if (stateColumns[i].field === defaultColumns[j].field)
          stateColumns[i].headerClassName =
            defaultColumns[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }

    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });

    setResult(processWithGroups(data, updatedState.dataState));
    setResultExport(processWithGroups(data, updatedStateExcel.dataState));
    setDataState(updatedState.dataState);
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const onClick = () => {
    //setShow(!show);
    setShow(true);
  };
  const exportPDF = () => {
    //let oldDataState = { ...dataState };
    // if (pdfExportComponent.current) {
    //setDataState({ ...dataState, skip: 0, take: Number.MAX_VALUE });

    _pdfExport.save();

    // setTimeout(() => {
    //   setDataState(oldDataState);
    // });
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({
      ...result,
      data: [...result.data],
    });
  };
  const handleColapse = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = false;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(false);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  const handleExpand = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = true;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(true);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  useEffect(() => {
    //handleColapse();
  }, []);

  const getCells = (columns, cellProps) => {
    let cells = [];
    console.log("Columns values:", columns);
    columns.forEach((column) => {
      var colStatus = Enumerable.from(stateColumns)
        .where((w) => w.field === column.field)
        .toArray();
      //if( colStatus.length!=0 && colStatus[0].show){
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.

      const columns = [];
      if (!tdElement.props.colSpan) {
        return <td></td>;
      }
      let currentColumns = stateColumns.filter((c) => c.show);
      currentColumns = currentColumns.map((col) => {
        let aggrCol = columns.find((c) => {
          return c.field == col.field;
        });
        if (aggrCol && aggrCol.aggregate) {
          console.log("aggregate");
          return {
            ...col,
            aggregate: aggrCol.aggregate,
          };
        } else {
          return col;
        }
      });
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - currentColumns.length}
          ></td>
          {getCells(
            currentColumns.sort((a, b) => {
              return a.orderIndex - b.orderIndex;
            }),
            cellProps
          )}
        </>
      );
    }

    if (cellProps.rowType === "data") {
      if (cellProps.field === "errorDt") {
        let cdt = new Date(cellProps.dataItem["errorDt"]);
        if (moment(cellProps.dataItem["errorDt"]).isValid()) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
              {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}{" "}
              {cdt.getHours().toString().padStart(2, "0")}:
              {cdt.getMinutes().toString().padStart(2, "0")}
              {/* {formatDate(cellProps.dataItem["loginDate"],"MM/dd/yyyy HH:mm")} */}
            </td>
          );
        } else {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {cellProps.dataItem["ErrorDt"]}
            </td>
          );
        }
      }
      // if (cellProps.field === "success") {
      //
      //   if(cellProps.dataItem[cellProps.field]){
      //     return (
      //       <td
      //         style={{ textAlign: "center" }}
      //         aria-colindex={cellProps.columnIndex}
      //         data-grid-col-index={cellProps.columnIndex}
      //         role={"gridcell"}
      //       >
      //         <FaCheck  style={{color:"green"}}/>

      //       </td>
      //     );
      //   }
      //   else{
      //   return (
      //     <td
      //       style={{ textAlign: "center" }}
      //       aria-colindex={cellProps.columnIndex}
      //       data-grid-col-index={cellProps.columnIndex}
      //       role={"gridcell"}
      //     >
      //       <IoClose  size={23} style={{color:"red"}} />
      //     </td>
      //   );
      //   }
      // }
    }

    return tdElement;
  };

  const windowHeight = useRef(window.innerHeight);

  var excelTitle =
    localStorage.getItem("SelAcctId") == 0
      ? "Consolidation:" + localStorage.getItem("pConsName")
      : "Account Number:" + localStorage.getItem("SelAcctNm");
  console.log("Consolidation result:", result);
  console.log(result);
  console.log(dataState);
  return (
    // <div className="row my-2">
    //   <div className="col col-md-12 col-sm-12 py-2">
    //     <div className="mx-1 px-1">
    <>
      <div>
        {/* <Modal title="Details" open={show} onOk={onClick} onCancel={onClick}  isActive="none" style={{zIndex:999999999}}
        footer={[
          <Button key="Ok" onClick={onClick}>
            Ok
          </Button>

        ]}

      >
        {details}
      </Modal> */}
      </div>

      <Grid
        style={{
          height: "55vh",

          zIndex: 999999999,
          border: "none",
        }}
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        onColumnReorder={onColumnReorder}
        onColumnResize={onColumnResize}
        expandField="expanded"
        onExpandChange={expandChange}
        cellRender={cellRender}
        sortable={true}
        resizable={true}
        //pageable={true}
        reorderable={true}
        pageSize={2000}
        groupable={{
          footer: "none",
          enabled: false,
        }}
      >
        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <Column
                width={setWidth(column.minWidth)}
                locked={column.locked}
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                footerCell={column.footerCell}
                headerClassName={column.headerClassName}
                {...column}
                // columnMenu={column.columnMenu}

                //cell={NumberCell}

                columnMenu={(props) => (
                  <>
                    {column.filter === "text" ? (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : column.filter === "numeric" ? (
                      <CustomColumnMenuNoGrp
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                  </>
                )}
              />
            )
        )}
      </Grid>

      {/* </div>
      </div>
    </div> */}
    </>
  );
};

export default ErrorLogDataGrid;
