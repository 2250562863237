import React, { useEffect, useState } from "react";
import { customToken } from "./customToken";
import axios from "axios";
import { weekInYear, addDays } from "@progress/kendo-date-math";
import Modal from "react-bootstrap/Modal";
import Enumerable from "linq";
import { filterBy } from "@progress/kendo-data-query";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import StatementGrid from "./statementGrid";
import { CustomCalendar } from "./customCalendar";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate } from "react-router-dom";
import { FaBookOpen, FaEye, FaPlus } from "react-icons/fa";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

import TextField from "@mui/material/TextField";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import { process } from "@progress/kendo-data-query";
import Loading from "./loading";

import CustomDashboardGrd from "./cstmDashboardGrd";

import { FcExpired } from "react-icons/fc";

const StatementRpt = ({ acctId }) => {
  const [loading, setLoading] = React.useState(true);
  const [statementData, SetstatementData] = React.useState([]);
  const [qualRpts, setRptList] = React.useState([]);
  const [flag, setFlag] = React.useState(0);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [toDate, setToDate] = React.useState(
    addDays(new Date(localStorage.getItem("processingDate")), 30)
  );
  var date = new Date(localStorage.getItem("processingDate"));

  //date.setMonth(date.getMonth() - 1);
  const [frmDate, setfrmDate] = React.useState(date);
  const maxDt = addDays(new Date(localStorage.getItem("processingDate")), 365);
  const minDt = date;
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        GetTmpltList();
      } catch (errMsg) {
        console.log(errMsg);
      }
    };
    fetchData();
  }, []);
  const GetTmpltList = async () => {
    let token = tempToken;
    let AcctId = JSON.parse(localStorage.getItem("SelAcctId"));
    let ConsolidationId = JSON.parse(localStorage.getItem("pConsId"));
    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");
    //let AcctId=2392;
    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const postData = { AcctId, ConsolidationId, RltnshpCd };

    await axios
      .post("/Statements", postData, config)
      .then((response) => {
        const rowData = response.data.lstStatements;
        SetstatementData(rowData);

        setLoading(false);
        setFlag(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        console.log("my error is " + error);
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData, config)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetTmpltList();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };
  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };
  // const handleChange = (event) => {
  //
  //       if (event.target.value === null) {
  //         SetselAcct(selAcct);
  //         //GetUpdatedAccountProfile(0);
  //         localStorage.setItem('IsAcctSelected', false);
  //         localStorage.setItem('AcctSelected',JSON.stringify(JSON.parse(localStorage.getItem('acctData'))[0]));
  //         }
  //       else {
  //         SetselAcct(event.target.value);

  //         localStorage.setItem('IsAcctSelected', true);
  //         localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));

  //         //GetUpdatedAccountProfile(event.target.value.acctId);
  //       }

  //     };
  // const filterChange = (event) => {

  //     setSelAcctData(filterData(event.filter));
  //   };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  function setDate(val) {
    if (val == null) {
      setfrmDate(frmDate);
      // var newToDate = new Date(frmDate);
      // newToDate.setMonth(val.getMonth() + 12);
      // if (newToDate >= new Date(localStorage.getItem('processingDate'))) {
      //   setToDate(new Date(localStorage.getItem('processingDate')));
      // }
      // else {
      //   setToDate(newToDate);
      // }
    } else {
      setfrmDate(val);

      //var newToDate = new Date(val);
      //newToDate.setMonth(val.getMonth() + 12);
      // if (newToDate >= new Date(localStorage.getItem('processingDate'))) {
      //   setToDate(new Date(localStorage.getItem('processingDate')));
      // }
      // else {
      //setToDate(newToDate);
    }
  }
  function setTDate(val) {
    if (val == null) {
      setToDate(toDate);
      // var newStartDate = new Date(toDate);
      // newStartDate.setMonth(val.getMonth() - 12);
      // setfrmDate(newStartDate);
    } else {
      setToDate(val);

      // var newStartDate = new Date(val);
      // newStartDate.setMonth(val.getMonth() - 12);
      // setfrmDate(newStartDate);
    }
  }
  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <Loading />
        )}
      </>
    );
  }

  return (
    <div className="pt-1">
      {/* <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
          value={frmDate}
          format="MM/dd/yyyy"
          calendar={CustomCalendar}
           min={minDt}
           max={maxDt}
         valid={frmDate<=toDate && frmDate<=maxDt &&frmDate>=minDt}
          onChange={(e) => {
            setDate(e.value);

          }}

        />

      </div>

      <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
          //defaultValue={toDate}
          value={toDate}
          format="MM/dd/yyyy"
          calendar={CustomCalendar}
          min={minDt}
           max={maxDt}
           valid={frmDate<=toDate && toDate<=maxDt &&toDate>=minDt}
          disabled={false}
          onChange={(e) => {
            setTDate(e.value);

          }}
        />

      </div> */}

      {flag === 1 ? <StatementGrid data={statementData} /> : <></>}
    </div>
  );
};
export default StatementRpt;
