import CryptoJS from "crypto-js";
export function customToken() {
  var KEY = process.env.REACT_APP_SEC_KEY; //"4C11247C33B640E08B235BA829C61DF9";//32 bit
  var IV = process.env.REACT_APP_IV; // "4C11247C33B640E0";//16 bits
  var key = CryptoJS.enc.Utf8.parse(KEY);
  var iv = CryptoJS.enc.Utf8.parse(IV);
  const randomNum = Math.floor(100000 + Math.random() * 900000);
  let headerUrl = localStorage.getItem("location") + randomNum;
  let srcsUrl = CryptoJS.enc.Utf8.parse(headerUrl);
  let encryptedHeaderUrl = CryptoJS.AES.encrypt(srcsUrl, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  debugger;
  return encryptedHeaderUrl.ciphertext.toString();
}
