import React, { useMemo } from "react";
import { customToken } from "./customToken";
import { Button, InputAdornment, TextField, Tooltip } from "@mui/material";
import { ColumnMenu } from "./columnMenu";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { blue, pink } from "@mui/material/colors";
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import { TextArea } from "@progress/kendo-react-inputs";
import { RadioButton } from "@progress/kendo-react-inputs";
// import Modal from '@mui/material/Modal';
import Modal from "react-bootstrap/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { MdOutlinePersonAddAlt } from "react-icons/md";
import FormLabel from "@mui/material/FormLabel";
import { ComboBox, DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade, Zoom } from "@progress/kendo-react-animation";
import { formatDate } from "@telerik/kendo-intl";
import { width } from "@mui/system";
import { encode, decode } from "html-entities";
import { useSelector } from "react-redux";
import { selectTheme, toggleTheme } from "../features/theme/themeSlice";
import { BsInfoCircleFill } from "react-icons/bs";
import Loading from "./loading";
import { Row } from "react-bootstrap";
import Enumerable from "linq";
var beneficiaryList;
var measuringLivesList;
var addbuttonclicked = false;
const TbGrid = ({ data, menuStateData }) => {
  const [newItem, setNewItem] = useState("");
  const [relationbuttonhover, setrelationbuttonhover] = useState(false);
  const listNoDataRender = (element) => {
    const noData = (
      <>
        <div>
          Relation not available.<br></br>
          <br></br>
          <div
            onClick={(e) => {
              SetNewRelation();
            }}
            onMouseDown={(e) => {
              if (relationbuttonhover) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onMouseEnter={(e) => {
              setrelationbuttonhover(true);
            }}
            onMouseLeave={(e) => {
              setrelationbuttonhover(false);
            }}
            style={{
              cursor: "pointer",
              color: "#008cff",
              fontSize: "15px",
              borderRadius: "10px",
              borderColor: "#75d1ff",
              borderStyle: "solid",
              borderWidth: relationbuttonhover ? "3px" : "2px",
              backgroundColor: relationbuttonhover
                ? "rgba(39, 166, 245, 0.1)"
                : "rgba(39, 166, 245, 0)",
              padding: "10px",
              zIndex: 9999999999999999999999999999999999999999999999999999999999,
            }}
          >
            <b>Add: "{newItem}" </b>
          </div>
        </div>
      </>
    );
    return React.cloneElement(
      element,
      {
        ...element.props,
      },
      noData
    );
  };
  const listNoDataRenderML = (element) => {
    const noData = (
      <>
        <div>
          Relation not available.<br></br>
          <br></br>
          <div
            onClick={SetNewRelationML}
            onMouseDown={(e) => {
              if (relationbuttonhover) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onMouseEnter={(e) => setrelationbuttonhover(true)}
            onMouseLeave={(e) => setrelationbuttonhover(false)}
            style={{
              cursor: "pointer",
              color: "#008cff",
              fontSize: "15px",
              borderRadius: "10px",
              borderColor: "#75d1ff",
              borderStyle: "solid",
              borderWidth: relationbuttonhover ? "3px" : "2px",
              backgroundColor: relationbuttonhover
                ? "rgba(39, 166, 245, 0.1)"
                : "rgba(39, 166, 245, 0)",
              padding: "10px",
              zIndex: 9999999999999999999999999999999999999999999999999999999999,
            }}
          >
            <b>Add: "{newItem}" </b>
          </div>
        </div>
      </>
    );
    return React.cloneElement(
      element,
      {
        ...element.props,
      },
      noData
    );
  };
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState("");
  const [tbData, setTbData] = useState(data);
  const max = 20;

  const [avlblRelation, setAvlblRelation] = useState(false);
  const [newRelation, setNewRelation] = useState("");
  const [valuePurpose, setValuePurpose] = React.useState(
    data.lstTrustBriefDtls[0].purpose
  );
  const [valueDistProvision, setValueDistProvision] = React.useState(
    data.lstTrustBriefDtls[0].distribProv
  );
  const [valueTermination, setValueTermination] = React.useState(
    data.lstTrustBriefDtls[0].termination
  );
  const [valueRemProvision, setValueRemProvision] = React.useState(
    data.lstTrustBriefDtls[0].remaindermen
  );
  const [valueOriginalCorpus, setValueOriginalCorpus] = React.useState(
    data.lstTrustBriefDtls[0].origCorp
  );
  const [valueInvestmentPowers, setValueInvestmentPowers] = React.useState(
    data.lstTrustBriefDtls[0].investmentPowers
  );
  const [valueTrustBriefNote, setValueTrustBriefNote] = React.useState(
    data.lstTrustBriefDtls[0].trustBriefNote
  );
  const [selectedValueInvPrincipal, setSelectedValueInvPrincipal] =
    React.useState(data.lstTrustBriefDtls[0].invPrincip === 0 ? "No" : "Yes");
  const [show, setShow] = React.useState(false);

  var alldata = data.lstTBRelation;
  const [comboLoading, setComboLoading] = React.useState(false);
  const [tbRelation, setTbRelation] = React.useState([data.lstTBRelation]);
  const [relationexist, setrelationexist] = useState(false);
  const [blankrelation, setblankrelation] = useState(false);
  const [tbRelData, setTbRelData] = React.useState(
    data.lstTBRelationDtls1.length === 0
      ? { tbRelationId: -1, tbRelationNm: "None" }
      : { tbRelationId: data.lstTBRelationDtls1 }
  );
  const [selectedtbRelation, setselectedTbRelation] = React.useState([]);
  const themes = useSelector(selectTheme);
  const [mlTbRelData, setMlTbRelData] = React.useState(
    data.lstTBRelationDtls2.length === 0
      ? { tbRelationId: -1, tbRelationNm: "None" }
      : { tbRelationId: data.lstTBRelationDtls2 }
  );
  const [selectedMlTbRelation, setSelectedMlTbRelation] = React.useState([]);
  if (!loading) {
    //for beneficiary grid
    for (var i = 0; i < data.lstTBRelationDtls1.length; i++) {
      selectedtbRelation[i] = {
        tbRelationId: data.lstTBRelationDtls1[i].tbRelationId,
        tbRelationNm: data.lstTBRelationDtls1[i].tbRelationNm,
      };
    }
    beneficiaryList = selectedtbRelation;
    localStorage.setItem("Beneficiary", JSON.stringify(beneficiaryList));

    //for measuring Lives grid
    for (var i = 0; i < data.lstTBRelationDtls2.length; i++) {
      selectedMlTbRelation[i] = {
        tbRelationId: data.lstTBRelationDtls2[i].tbRelationId,
        tbRelationNm: data.lstTBRelationDtls2[i].tbRelationNm,
      };
    }
    measuringLivesList = selectedMlTbRelation;
    localStorage.setItem("MeasuringLives", JSON.stringify(measuringLivesList));
  } else {
    //for beneficiary grid
    setselectedTbRelation(JSON.parse(localStorage.getItem("Beneficiary")));
    beneficiaryList = JSON.parse(localStorage.getItem("Beneficiary"));

    //for measuring Lives grid
    setSelectedMlTbRelation(JSON.parse(localStorage.getItem("MeasuringLives")));
    measuringLivesList = JSON.parse(localStorage.getItem("MeasuringLives"));
  }
  if (loading) {
    setLoading(false);
  }
  // const defaultItem = {
  //     tbRelationId: data.lstTBRelationDtls1[0].tbRelationId,
  //     tbRelationNm: data.lstTBRelationDtls1[0].tbRelationNm
  //   };
  const [error, setError] = React.useState(false);
  const [rltnaddFlag, setrltnaddFlag] = React.useState(false);
  useEffect(() => {
    if (show) {
      menuStateData(true);
    }
  }, []);
  //   useMemo(() => {
  //
  //     setselectedTbRelation(beneficiaryList);

  //   }, [selectedtbRelation])

  const handleChangeInvPrincipal = (event) => {
    setSelectedValueInvPrincipal(event.target.value);
  };
  const handleAddRelation = (e) => {
    e.preventDefault();
  };
  const handleNewRelation = (e) => {
    setrelationexist(false);
    setblankrelation(false);
    setNewRelation(e.target.value);
  };
  const handleChangePurpose = (e) => {
    setValuePurpose(e.target.value);
  };
  const handleChangeDistProvision = (e) => {
    setValueDistProvision(e.target.value);
  };
  const handleChangeTermination = (e) => {
    setValueTermination(e.target.value);
  };
  const handleChangeRemProvision = (e) => {
    setValueRemProvision(e.target.value);
  };
  const handleChangeorigCorp = (e) => {
    setValueOriginalCorpus(e.target.value);
  };
  const handleChangeInvPowers = (e) => {
    setValueInvestmentPowers(e.target.value);
  };
  const handleChangeTrstBrfNote = (e) => {
    setValueTrustBriefNote(e.target.value);
  };
  const onSaveClicked = async () => {
    SetTrustBriefData();
  };
  const handleChangeTbRelation = (e, x) => {
    if (e.value !== null) {
      console.log(selectedtbRelation);
      beneficiaryList = selectedtbRelation;
      beneficiaryList[x] = e.value;
      setselectedTbRelation(beneficiaryList);
      //setBeneficiaryVal(beneficiaryList[x]);
      localStorage.setItem("Beneficiary", JSON.stringify(beneficiaryList));

      measuringLivesList = selectedMlTbRelation;
      localStorage.setItem(
        "MeasuringLives",
        JSON.stringify(measuringLivesList)
      );
      setLoading(true);

      for (var i = 0; i < data.lstTBRelationDtls1.length; i++) {
        data.lstTBRelationDtls1[i].tbRelationId =
          beneficiaryList[i].tbRelationId;
        data.lstTBRelationDtls1[i].tbRelationNm =
          beneficiaryList[i].tbRelationNm;
      }
    }
  };
  const SetNewRelation = async () => {
    setLoading(true);
    let token = tempToken;
    var check;
    var str1check = newItem.replace(/[^a-zA-Z -]/g, "");
    var str2check = str1check.replace(/\s+/g, "");

    if (str2check !== "") {
      let TbRelationNm = newItem;
      const postData = { TbRelationNm };
      const config = {
        headers: {
          "X-Custom-Header": localStorage.getItem("location"),
          FiSignature: customToken(),

          authorization: `Bearer ${token.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      await axios
        .post("/AdvTbRelationAdd", postData, config)
        .then((response) => {
          setTbRelation([response.data.lstTBRelation]);
          data.lstTBRelation = response.data.lstTBRelation;
          console.log(response);
          setLoading(false);
          setNewRelation("");
          setrltnaddFlag(true);
          setTimeout(() => {
            setrltnaddFlag(false);
          }, 5500);
          const rowData = response.data;

          var newObj = Enumerable.from(response.data.lstTBRelation)
            .where((w) => w.tbRelationNm === newItem)
            .toArray();
          let lTemp = JSON.parse(localStorage.getItem("lTemp"));

          beneficiaryList = selectedtbRelation;
          beneficiaryList[lTemp] = newObj[0];
          setselectedTbRelation(beneficiaryList);
          //setBeneficiaryVal(beneficiaryList[x]);
          localStorage.setItem("Beneficiary", JSON.stringify(beneficiaryList));
          for (var i = 0; i < data.lstTBRelationDtls1.length; i++) {
            data.lstTBRelationDtls1[i].tbRelationId =
              beneficiaryList[i].tbRelationId;
            data.lstTBRelationDtls1[i].tbRelationNm =
              beneficiaryList[i].tbRelationNm;
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshTokenSetRelation();
          }
          //return error;
        });
    } else {
      alert("Invalid characters");
    }
  };
  const SetNewRelationML = async () => {
    setLoading(true);
    let token = tempToken;
    var check;
    var str1check = newItem.replace(/[^a-zA-Z -]/g, "");
    var str2check = str1check.replace(/\s+/g, "");

    if (str2check !== "") {
      let TbRelationNm = newItem;
      const postData = { TbRelationNm };
      const config = {
        headers: {
          "X-Custom-Header": localStorage.getItem("location"),
          FiSignature: customToken(),

          authorization: `Bearer ${token.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      await axios
        .post("/AdvTbRelationAdd", postData, config)
        .then((response) => {
          setTbRelation([response.data.lstTBRelation]);
          data.lstTBRelation = response.data.lstTBRelation;
          console.log(response);
          setLoading(false);
          setNewRelation("");
          setrltnaddFlag(true);
          setTimeout(() => {
            setrltnaddFlag(false);
          }, 5500);
          const rowData = response.data;

          var newObj = Enumerable.from(response.data.lstTBRelation)
            .where((w) => w.tbRelationNm === newItem)
            .toArray();
          let lTemp = JSON.parse(localStorage.getItem("lTemp"));

          measuringLivesList = selectedMlTbRelation;
          measuringLivesList[lTemp] = newObj[0];
          setSelectedMlTbRelation(measuringLivesList);
          //setBeneficiaryVal(beneficiaryList[x]);
          localStorage.setItem(
            "MeasuringLives",
            JSON.stringify(measuringLivesList)
          );
          beneficiaryList = selectedtbRelation;
          localStorage.setItem("Beneficiary", JSON.stringify(beneficiaryList));
          setLoading(true);
          for (var i = 0; i < data.lstTBRelationDtls2.length; i++) {
            data.lstTBRelationDtls2[i].tbRelationId =
              measuringLivesList[i].tbRelationId;
            data.lstTBRelationDtls2[i].tbRelationNm =
              measuringLivesList[i].tbRelationNm;
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshTokenSetRelation();
          }
          //return error;
        });
    } else {
      alert("Invalid characters");
    }
  };
  const filterData = (filter) => {
    const data1 = data.lstTBRelation.slice();
    return filterBy(data1, filter);
  };
  const timeout = React.useRef();
  const filterChange = (event, temp) => {
    event.filter.operator = "startswith";

    localStorage.setItem("lTemp", temp);

    setNewItem(event.filter.value);
    setTbRelation([filterData(event.filter)]);

    // clearTimeout(timeout.current);
    //console.log([filterData(event.filter)]);

    // timeout.current = setTimeout(() => {

    //   setComboLoading(false);
    // }, 400);
    // setComboLoading(true);
  };
  const handleChangeTbRelationML = (e, x) => {
    measuringLivesList = selectedMlTbRelation;
    measuringLivesList[x] = e.value;
    setSelectedMlTbRelation(measuringLivesList);
    //setBeneficiaryVal(beneficiaryList[x]);
    localStorage.setItem("MeasuringLives", JSON.stringify(measuringLivesList));
    beneficiaryList = selectedtbRelation;
    localStorage.setItem("Beneficiary", JSON.stringify(beneficiaryList));
    setLoading(true);
    for (var i = 0; i < data.lstTBRelationDtls2.length; i++) {
      data.lstTBRelationDtls2[i].tbRelationId =
        measuringLivesList[i].tbRelationId;
      data.lstTBRelationDtls2[i].tbRelationNm =
        measuringLivesList[i].tbRelationNm;
    }
  };
  const SetTrustBriefData = async () => {
    let token = tempToken;
    let CnsldtnId = JSON.parse(localStorage.getItem("pConsId"));
    if (valuePurpose === null || valuePurpose === "") {
      setValuePurpose("");
    }
    if (valueDistProvision === null || valueDistProvision === "") {
      setValueDistProvision("");
    }
    if (valueTermination === null || valueTermination === "") {
      setValueTermination("");
    }
    if (valueRemProvision === null || valueRemProvision === "") {
      setValueRemProvision("");
    }
    if (valueOriginalCorpus === null || valueOriginalCorpus === "") {
      setValueOriginalCorpus("");
    }
    if (valueInvestmentPowers === null || valueInvestmentPowers === "") {
      setValueInvestmentPowers("");
    }
    if (valueTrustBriefNote === null || valueTrustBriefNote === "") {
      setValueTrustBriefNote("");
    }

    let XmlTbMain = "<TbMainsData>";
    XmlTbMain += "<TbMains>";
    XmlTbMain += "<CnsldtnId>" + CnsldtnId;
    XmlTbMain += "</CnsldtnId>";
    XmlTbMain += "<AcctId>" + data.lstTrustBriefDtls[0].acctId;
    XmlTbMain += "</AcctId>";
    XmlTbMain += "<Purpose>" + encode(valuePurpose);
    XmlTbMain += "</Purpose>";
    XmlTbMain += "<DistribProv>" + encode(valueDistProvision);
    XmlTbMain += "</DistribProv>";
    XmlTbMain += "<Termination>" + encode(valueTermination);
    XmlTbMain += "</Termination>";
    XmlTbMain += "<Remaindermen>" + encode(valueRemProvision);
    XmlTbMain += "</Remaindermen>";
    XmlTbMain += "<InvPrincip>";
    XmlTbMain += selectedValueInvPrincipal === "Yes" ? 1 : 0;
    XmlTbMain += "</InvPrincip>";
    XmlTbMain += "<OrigCorp>" + encode(valueOriginalCorpus);
    XmlTbMain += "</OrigCorp>";
    XmlTbMain += "<InvestmentPowers>" + encode(valueInvestmentPowers);
    XmlTbMain += "</InvestmentPowers>";
    XmlTbMain += "<TrustBriefNote>" + encode(valueTrustBriefNote);
    XmlTbMain += "</TrustBriefNote>";
    XmlTbMain += "</TbMains>";
    XmlTbMain += "</TbMainsData>";

    let XmlTbRelDtls = "<TbRelDetailsData>";
    //data.lstTBRelationDtls1
    for (var i = 0; i < data.lstTBRelationDtls1.length; i++) {
      XmlTbRelDtls += "<TbRelDetails>";
      XmlTbRelDtls += "<CnsldtnId>" + data.lstTBRelationDtls1[i].cnsldtnId;
      XmlTbRelDtls += "</CnsldtnId>";
      XmlTbRelDtls += "<AcctId>" + data.lstTBRelationDtls1[i].acctId;
      XmlTbRelDtls += "</AcctId>";
      XmlTbRelDtls +=
        "<TbRelationId>" +
        JSON.parse(localStorage.getItem("Beneficiary"))[i].tbRelationId;
      //data.lstTBRelationDtls1[i].cnsldtnId+ "_" +data.lstTBRelationDtls1[i].acctId + "_" +
      //tbRelData.tbRelationId+ "_" +data.lstTBRelationDtls1[i].cntctId;
      //$("#selTB_" + gridData[i].CnsldtnId + "_" + gridData[i].AcctId + "_" + gridData[i].TbRelationId + "_" + gridData[i].CntctId).val()
      XmlTbRelDtls += "</TbRelationId>";
      XmlTbRelDtls += "<CntctId>" + data.lstTBRelationDtls1[i].cntctId;
      XmlTbRelDtls += "</CntctId>";
      XmlTbRelDtls += "</TbRelDetails>";
    }
    XmlTbRelDtls += "</TbRelDetailsData>";

    let XmlTbRelMl = "<TbRelDetailsData>";
    for (var i = 0; i < data.lstTBRelationDtls2.length; i++) {
      XmlTbRelMl += "<TbRelDetails>";
      XmlTbRelMl += "<CnsldtnId>" + data.lstTBRelationDtls2[i].cnsldtnId;
      XmlTbRelMl += "</CnsldtnId>";
      XmlTbRelMl += "<AcctId>" + data.lstTBRelationDtls2[i].acctId;
      XmlTbRelMl += "</AcctId>";
      XmlTbRelMl +=
        "<TbRelationId>" +
        JSON.parse(localStorage.getItem("MeasuringLives"))[i].tbRelationId;
      //data.lstTBRelationDtls2[i].cnsldtnId+ "_" +data.lstTBRelationDtls2[i].acctId + "_" +
      //tbRelData.tbRelationId+ "_" +data.lstTBRelationDtls2[i].cntctId;
      //$("#selTBML_" + gridData[i].CnsldtnId + "_" + gridData[i].AcctId + "_" + gridData[i].TbRelationId + "_" + gridData[i].CntctId).val()
      XmlTbRelMl += "</TbRelationId>";
      XmlTbRelMl += "<CntctId>" + data.lstTBRelationDtls2[i].cntctId;
      XmlTbRelMl += "</CntctId>";
      XmlTbRelMl += "</TbRelDetails>";
    }

    XmlTbRelMl += "</TbRelDetailsData>";
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let IsDeleted = 0;

    const postData = { XmlTbMain, XmlTbRelDtls, XmlTbRelMl, UserId, IsDeleted };
    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/AdvTrustBriefSet", postData, config)
      .then((response) => {
        setLoading(false);
        //setFlag(true);
        const rowData = response.data;
        setTbData(rowData);
        setSelectedValueInvPrincipal(
          rowData.lstTrustBriefDtls[0].invPrincip === 0 ? "No" : "Yes"
        );
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 5500);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        //return error;
      });
  };
  const refreshTokenSetRelation = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        SetNewRelation();
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }

        console.log("my error is " + error);
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData, config)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        SetTrustBriefData();
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }

        console.log("my error is " + error);
      });
  };
  let allPDFExport;
  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };

  const handleClose = () => {
    setShow(false);
    menuStateData(false);
  };
  const openModal = () => {
    setShow(true);
    menuStateData(true);
  };
  const PageTemplate = (props) => {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "1px",

            width: "98%",
            borderBottom: "1px solid #bce8f1",
          }}
        >
          <div className="row d-flex mx-3">
            <div className="col text-start py-2">
              <h2 className="reportheading fs-6 ">Trust Brief</h2>
            </div>
            <div className="col text-start  py-2">
              <h2 className="reportheading fs-6 ">
                {localStorage.getItem("pConsName")}
              </h2>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            //  borderTop: "1px solid #bce8f1"
          }}
        >
          Page {props.pageNum} of {props.totalPages}
        </div>
      </div>
    );
  };

  const DateCell = (props) => {
    if (props.field === "brthDt") {
      let cdt = new Date(props.dataItem["brthDt"]);
      return (
        <td style={{ textAlign: "left" }}>
          {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
          {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
        </td>
      );
    }
    if (props.field === "dthDt") {
      let cdt = new Date(props.dataItem["dthDt"]);
      return (
        <td style={{ textAlign: "left" }}>
          {(cdt.getMonth() + 1).toString().padStart(2, "0")}/
          {cdt.getDate().toString().padStart(2, "0")}/{cdt.getFullYear()}
        </td>
      );
    }
  };
  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "data") {
      if (cellProps.field === "brthDt") {
        let cdt = new Date(cellProps.dataItem["brthDt"]);
        if (!isNaN(cdt.getTime())) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {formatDate(cdt, "MM/dd/yyyy") === "01/01/0001"
                ? ""
                : formatDate(cdt, "MM/dd/yyyy")}
            </td>
          );
        } else {
          return <td style={{ textAlign: "left" }}>&nbsp;</td>;
        }
      }
      if (cellProps.field === "dthDt") {
        let cdt = new Date(cellProps.dataItem["dthDt"]);
        if (!isNaN(cdt.getTime())) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {formatDate(cdt, "MM/dd/yyyy") === "01/01/0001"
                ? ""
                : formatDate(cdt, "MM/dd/yyyy")}
            </td>
          );
        } else {
          return <td style={{ textAlign: "left" }}>&nbsp;</td>;
        }
      }
      if (cellProps.field === "tbRelationNm") {
        var temp = cellProps.dataIndex;

        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            <ComboBox
              allowCustom={true}
              onFilterChange={(e, x) => filterChange(e, temp)}
              loading={comboLoading}
              listNoDataRender={listNoDataRender}
              filterable={true}
              style={{
                width: "300px",
              }}
              onChange={(e, x) => handleChangeTbRelation(e, temp)}
              clearButton={false}
              data={tbRelation[0]}
              textField="tbRelationNm"
              //dataItemKey='tbRelationId'
              defaultValue={selectedtbRelation[temp]}
              //defaultValue={tbRelData}
              //defaultItem={defaultItem}
              id="relationId"
            />
          </td>
        );
      }
    }
    return tdElement;
  };
  const cellRender1 = (tdElement, cellProps) => {
    if (cellProps.rowType === "data") {
      if (cellProps.field === "brthDt") {
        let cdt = new Date(cellProps.dataItem["brthDt"]);
        if (!isNaN(cdt.getTime())) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {formatDate(cdt, "MM/dd/yyyy") === "01/01/0001"
                ? ""
                : formatDate(cdt, "MM/dd/yyyy")}
            </td>
          );
        } else {
          return <td style={{ textAlign: "left" }}>&nbsp;</td>;
        }
      }
      if (cellProps.field === "dthDt") {
        let cdt = new Date(cellProps.dataItem["dthDt"]);
        if (!isNaN(cdt.getTime())) {
          return (
            <td
              style={{ textAlign: "left" }}
              data-grid-col-index={cellProps.columnIndex}
            >
              {formatDate(cdt, "MM/dd/yyyy") === "01/01/0001"
                ? ""
                : formatDate(cdt, "MM/dd/yyyy")}
            </td>
          );
        } else {
          return <td style={{ textAlign: "left" }}>&nbsp;</td>;
        }
      }
      if (cellProps.field === "tbRelationNm") {
        var temp = cellProps.dataIndex;
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            <ComboBox
              style={{
                width: "300px",
              }}
              onChange={(e, x) => handleChangeTbRelationML(e, temp)}
              clearButton={false}
              allowCustom={true}
              onFilterChange={(e, x) => filterChange(e, temp)}
              loading={comboLoading}
              listNoDataRender={listNoDataRenderML}
              filterable={true}
              data={tbRelation[0]}
              textField="tbRelationNm"
              //dataItemKey='tbRelationId'
              defaultValue={selectedMlTbRelation[temp]}
              //defaultValue={tbRelData}
              //defaultItem={defaultItem}
              id="relationId"
            />
          </td>
        );
      }
    }
    return tdElement;
  };
  if (loading) {
    return (
      <>
        {
          <div>
            {/* <Header></Header> */}
            <Loading />
          </div>
        }
      </>
    );
  }
  return (
    <div>
      <NotificationGroup
        style={{
          backgroundColor: "red",

          zIndex: 99999999,
          right: "48%",
          top: "45%",

          alignItems: "center",
          flexWrap: "wrap-reverse",
        }}
      >
        <Zoom>
          {error && (
            <Notification
              style={{ color: "white", backgroundColor: "#007cc4" }}
              type={{
                style: "Success",
                icon: false,
              }}

              //style={{backgroundColor:"#de0f00", color:"white"}}
              // closable={true}

              // onClose={() =>
              //   setError(false)
              // }
            >
              <span>
                <b>Data saved successfully!</b>
              </span>
            </Notification>
          )}
        </Zoom>
      </NotificationGroup>
      <NotificationGroup
        style={{
          backgroundColor: "green !important",
          color: "white !important",
          zIndex: 99999999,
          right: "50%",
          top: "18%",

          alignItems: "center",
          flexWrap: "wrap-reverse",
        }}
      >
        <Zoom>
          {rltnaddFlag && (
            <Notification
              style={{ color: "white", backgroundColor: "#539c00" }}
              type={{
                style: "success",

                icon: false,
              }}

              //style={{backgroundColor:"#de0f00", color:"white"}}
              // closable={true}

              // onClose={() =>
              //   setError(false)
              // }
            >
              <span>
                <b>Relation added successfully!</b>
              </span>
            </Notification>
          )}
        </Zoom>
      </NotificationGroup>
      <div className="container pt-1">
        <div className="card kpicard ">
          <div
            className="card-header"
            style={
              themes === "dark" && !show
                ? { background: "#292929" }
                : { background: "white" }
            }
          >
            <div className="d-flex align-items-center">
              <div className="me-auto">
                <h6 className="mb-0">Trust Brief</h6>
              </div>
              {/* <div>
        {/* <><ClickAwayListener onClickAway={(e)=>{setAvlblRelation(false)}}><Tooltip
                PopperProps={{
                  disablePortal: false,
                }}
                arrow
                onClose={(e)=>{setAvlblRelation(false)}}
                //open={allowedroledisp}
                disableFocusListener

                disableTouchListener
                placement="left"
                title={<><>Available Relations</>{tbRelation[0].map((rltn) => (

                    <li>{rltn.tbRelationNm}</li>
                ))}</>}
              >

              <a style={{top:'10px'}} onClick={(e)=>{setAvlblRelation(true)}}><BsInfoCircleFill size={20} color='#2c9ff2'></BsInfoCircleFill></a></Tooltip></ClickAwayListener></>&nbsp;&nbsp;&nbsp; 
        <TextField id="outlined-basic"  sx={themes==="light"?{
        // Root class for the input field
        "& .MuiOutlinedInput-root": {
         
          
          
          // Class for the border around the input field
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2e2e2e",
           
          },
          "& .MuiOutlinedInput-notchedOutline:focused": {
            borderColor: "#2e2e2e !important",
           
          },
        },
        // Class for the label of the input field
       
      }:{}}
      helperText={relationexist?"Relation already exists":blankrelation?"Enter valid relation":""}
      error={relationexist||blankrelation}
       label="Add Relations" variant="outlined"
         InputLabelProps={{
          style: { color: themes==="light"?'#808080':'#7acaff' },
        }}
style={{paddingRight:'0px',borderBottomRightRadius:'0px'}}
        // InputLabelProps={{
        //      style: { color: '#808080' },
        //    }}
  onChange={handleNewRelation}
  value={newRelation}
         InputProps={{ 
          sx: { borderTopRightRadius: 0,borderBottomRightRadius:0 },
                   
                }} 
  />
        <Button onClick={handleAddRelation} style={{backgroundColor:'#1279ff',width:'5px',height:'56px',borderBottomLeftRadius:'0px',borderTopLeftRadius:'0px',"&:hover": {
      background: "#efefef"
    }}}><MdOutlinePersonAddAlt  color='white'  size={20}/></Button>
        </div>&nbsp;&nbsp;&nbsp;&nbsp; */}
              <div>
                <button
                  className="btn btn-primary btn-sm me-3"
                  disabled={
                    tbData.lstTrustBriefDtls[0].acctId === 0 ? true : false
                  }
                  onClick={openModal}
                >
                  Preview
                </button>
              </div>
              <div>
                <button
                  className="btn btn-success btn-sm me-2"
                  onClick={onSaveClicked}
                >
                  Save
                </button>
              </div>
            </div>
            <div>
              {/* Uncomment this button if needed */}
              {/* <button className="btn btn-primary btn-sm" onClick={onCancelClicked}>
          Cancel
        </button> */}
            </div>
          </div>
          <div
            className="card-body"
            style={
              themes === "dark"
                ? { background: "#292929" }
                : { background: "white" }
            }
          >
            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Trust Title:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  {data.lstTrustBriefDtls[0].trustTitle}
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Entity No:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  {data.lstTrustBriefDtls[0].entityNo}
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Grantor/Testator:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  {data.lstTrustBriefDtls[0].grantor_Testator}
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Trustee(s):
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  {data.lstTrustBriefDtls[0].trustee}
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Type of Trust:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  {data.lstTrustBriefDtls[0].typeOfTrust}
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Purpose:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  <TextArea
                    style={{
                      width: "100%",
                      backgroundColor:
                        themes === "dark" ? "#575757" : "#f2f2f2",
                      color: themes === "dark" ? "white" : "black",
                    }}
                    value={valuePurpose}
                    onChange={handleChangePurpose}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Distribution Provisions:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  <TextArea
                    style={{
                      width: "100%",
                      backgroundColor:
                        themes === "dark" ? "#575757" : "#f2f2f2",
                      color: themes === "dark" ? "white" : "black",
                    }}
                    value={valueDistProvision}
                    onChange={handleChangeDistProvision}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Termination:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  <TextArea
                    style={{
                      width: "100%",
                      backgroundColor:
                        themes === "dark" ? "#575757" : "#f2f2f2",
                      color: themes === "dark" ? "white" : "black",
                    }}
                    value={valueTermination}
                    onChange={handleChangeTermination}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Remainder Provisions:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  <TextArea
                    style={{
                      width: "100%",
                      backgroundColor:
                        themes === "dark" ? "#575757" : "#f2f2f2",
                      color: themes === "dark" ? "white" : "black",
                    }}
                    value={valueRemProvision}
                    onChange={handleChangeRemProvision}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Invade Principal:
                </label>
              </div>
              <div className="col-md-8">
                <div className="d-flex align-items-center fw-bold">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={handleChangeInvPrincipal}
                      value={selectedValueInvPrincipal}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={
                          <Radio
                            sx={{
                              color: blue[800],
                              "&.Mui-checked": {
                                color: blue[600],
                              },
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={
                          <Radio
                            sx={{
                              color: blue[800],
                              "&.Mui-checked": {
                                color: blue[600],
                              },
                            }}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  GST Taxable:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  <TextArea
                    style={{
                      width: "100%",
                      backgroundColor:
                        themes === "dark" ? "#575757" : "#f2f2f2",
                      color: themes === "dark" ? "white" : "black",
                    }}
                    maxLength={max}
                    value={data.lstTrustBriefDtls[0].gstTaxable}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Return Type:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  <TextArea
                    style={{
                      width: "100%",
                      backgroundColor:
                        themes === "dark" ? "#575757" : "#f2f2f2",
                      color: themes === "dark" ? "white" : "black",
                    }}
                    maxLength={max}
                    value={data.lstTrustBriefDtls[0].taxReturnType}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-3 pb-2 border-bottom border-gray">
              <div className="col-md-4">
                <label
                  className="text-fitek"
                  style={
                    themes === "dark" ? { color: "white" } : { color: "black" }
                  }
                >
                  Original Corpus:
                </label>
              </div>
              <div className="col-md-8">
                <div className="fw-bold">
                  <TextArea
                    style={{
                      width: "100%",
                      backgroundColor:
                        themes === "dark" ? "#575757" : "#f2f2f2",
                      color: themes === "dark" ? "white" : "black",
                    }}
                    value={valueOriginalCorpus}
                    onChange={handleChangeorigCorp}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="row mb-3 pb-2 border-bottom border-gray">
                <div className="col-md-4">
                  <label
                    className="text-fitek"
                    style={
                      themes === "dark"
                        ? { color: "white" }
                        : { color: "black" }
                    }
                  >
                    Investment Powers:
                  </label>
                </div>
                <div className="col-md-8">
                  <div className="fw-bold">
                    <TextArea
                      style={{
                        width: "100%",
                        backgroundColor:
                          themes === "dark" ? "#575757" : "#f2f2f2",
                        color: themes === "dark" ? "white" : "black",
                      }}
                      value={valueInvestmentPowers}
                      onChange={handleChangeInvPowers}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3 pb-2 border-bottom border-gray">
                <div className="col-md-4">
                  <label
                    className="text-fitek"
                    style={
                      themes === "dark"
                        ? { color: "white" }
                        : { color: "black" }
                    }
                  >
                    Trust Brief Note:
                  </label>
                </div>
                <div className="col-md-8">
                  <div className="fw-bold">
                    <TextArea
                      maxLength={max}
                      style={{
                        width: "100%",
                        backgroundColor:
                          themes === "dark" ? "#575757" : "#f2f2f2",
                        color: themes === "dark" ? "white" : "black",
                      }}
                      value={valueTrustBriefNote}
                      onChange={handleChangeTrstBrfNote}
                    />
                  </div>
                </div>
              </div>
              <div></div>
              <hr></hr>
              <div
                className=" text-fitek"
                style={
                  themes === "dark" ? { color: "white" } : { color: "black" }
                }
              >
                Beneficiaries{" "}
              </div>

              {/* {data.lstTBRelationDtls1.length === 0 ? <></> : */}
              {loading ? (
                <></>
              ) : (
                <Grid
                  style={{ height: "auto" }}
                  data={data.lstTBRelationDtls1}
                  resizable={true}
                  reorderable={true}
                  sortable={true}
                  cellRender={cellRender}
                >
                  <Column field="nm" menu={true} title="Name" width="auto" />
                  <Column
                    field="tbRelationNm"
                    title="Relationships"
                    width="350px"
                  />
                  <Column
                    field="brthDt"
                    menu={true}
                    title="Date of Birth"
                    width="auto"
                  />
                  <Column
                    field="dthDt"
                    menu={true}
                    title="Date of Death"
                    width="auto"
                  />
                </Grid>
              )}
            </div>
            <div>
              <br></br>
              <div
                className=" text-fitek"
                style={
                  themes === "dark" ? { color: "white" } : { color: "black" }
                }
              >
                Measuring Lives{" "}
              </div>
              {/* {data.lstTBRelationDtls2.length === 0 ? <></> : */}
              <Grid
                style={{ height: "auto" }}
                data={data.lstTBRelationDtls2}
                resizable={true}
                reorderable={true}
                sortable={true}
                cellRender={cellRender1}
              >
                <Column field="nm" menu={true} title="Name" width="auto" />
                <Column
                  field="tbRelationNm"
                  title="Relationships"
                  width="350px"
                />
                <Column
                  field="brthDt"
                  menu={true}
                  title="Date of Birth"
                  width="auto"
                />
                <Column
                  field="dthDt"
                  menu={true}
                  title="Date of Death"
                  width="auto"
                />
              </Grid>
            </div>
          </div>
          {/* <div className="card-footer text-end">
      <button
        className="btn btn-primary btn-sm me-2"
        disabled={tbData.lstTrustBriefDtls[0].acctId === 0 ? true : false}
        onClick={openModal}
      >
        Preview
      </button>
      <button className="btn btn-success btn-sm" onClick={onSaveClicked}>
        Save
      </button>
    </div> */}
        </div>
      </div>

      <div className="input-container">
        <Modal size="lg" show={show}>
          <>
            <br></br>
            <div className="card-header tableheader"></div>

            <div className="bg-secondary text-light d-flex align-items-center">
              <div className="me-auto p-2">You are in Print Preview Mode</div>
              <div className="p-2">
                <button
                  className="btn btn-primary rounded  btn-sm"
                  onClick={printPDF}
                >
                  Print
                </button>
              </div>
              <div className="p-2">
                <button
                  className="btn btn-light rounded  btn-sm"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>

            <div className="card-body tbbody">
              {tbData.lstTrustBriefDtls[0].acctId === 0 ? (
                <></>
              ) : (
                <PDFExport
                  margin={{ top: 30, left: 20, right: 40, bottom: 20 }}
                  pageTemplate={PageTemplate}
                  forcePageBreak=".page-break"
                  paperSize="A4"
                  scale={0.8}
                  allPages={true}
                  fileName={
                    localStorage.getItem("pConsName") + "_TrustBriefReport.pdf"
                  }
                  landscape={false}
                  repeatHeaders={true}
                  ref={(pdfExport) => (allPDFExport = pdfExport)}
                >
                  <div className="container pt-3">
                    <div className="card border-0">
                      <div className="card-body  m-2">
                        <div className="text-start text-dark fw-500 h5 text-decoration-underline pb-3">
                          Trust Brief Report
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">Trust Title:</label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">
                              {tbData.lstTrustBriefDtls[0].trustTitle}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">Entity No:</label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">
                              {tbData.lstTrustBriefDtls[0].entityNo}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">
                              Grantor/Testator:
                            </label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">
                              {tbData.lstTrustBriefDtls[0].grantor_Testator}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">Trustee(s):</label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">
                              {tbData.lstTrustBriefDtls[0].trustee}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">Type of Trust:</label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">
                              {tbData.lstTrustBriefDtls[0].typeOfTrust}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">Purpose:</label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">{valuePurpose}</div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">
                              Distribution Provisions:
                            </label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">{valueDistProvision}</div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">Termination:</label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">{valueTermination}</div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">
                              Remainder Provisions:
                            </label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">{valueRemProvision}</div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">
                              Invade Principal:
                            </label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">
                              {selectedValueInvPrincipal}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">GST Taxable:</label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">
                              {tbData.lstTrustBriefDtls[0].gstTaxable}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">Return Type:</label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">
                              {tbData.lstTrustBriefDtls[0].taxReturnType}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">
                              Original Corpus:
                            </label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">{valueOriginalCorpus}</div>
                          </div>
                        </div>

                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">
                              Investment Powers:
                            </label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">
                              {valueInvestmentPowers}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3 pb-2 border-bottom border-gray">
                          <div className="col-md-3">
                            <label className="text-fitek">
                              Trust Brief Note:
                            </label>
                          </div>
                          <div className="col-md-8">
                            <div className="fw-bold">{valueTrustBriefNote}</div>
                          </div>
                        </div>
                        {data.lstTBRelationDtls1.length === 0 ? (
                          <></>
                        ) : (
                          <>
                            <hr className="my-5"></hr>

                            <h5 className="text-center mb-4">Beneficiaries</h5>

                            <div className="mx-2">
                              <table className="table table-bordered table-striped mx-1">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Relationships</th>
                                    <th>Date of Birth</th>
                                    <th>Date of Death</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tbData.lstTBRelationDtls1.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td>{item.nm}</td>
                                        <td>{item.tbRelationNm}</td>
                                        <td>
                                          {formatDate(
                                            new Date(item.brthDt),
                                            "MM/dd/yyyy"
                                          ) === "01/01/0001"
                                            ? ""
                                            : formatDate(
                                                new Date(item.brthDt),
                                                "MM/dd/yyyy"
                                              )}
                                        </td>
                                        <td>
                                          {formatDate(
                                            new Date(item.dthDt),
                                            "MM/dd/yyyy"
                                          ) === "01/01/0001"
                                            ? ""
                                            : formatDate(
                                                new Date(item.dthDt),
                                                "MM/dd/yyyy"
                                              )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                        {data.lstTBRelationDtls2.length === 0 ? (
                          <></>
                        ) : (
                          <>
                            <h5 className="text-center mb-4 mt-5">
                              Measuring Lives
                            </h5>
                            <table className="table table-bordered table-striped mx-1">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Relationships</th>
                                  <th>Date of Birth</th>
                                  <th>Date of Death</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tbData.lstTBRelationDtls2.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td>{item.nm}</td>
                                      <td>{item.tbRelationNm}</td>
                                      <td>
                                        {formatDate(
                                          new Date(item.brthDt),
                                          "MM/dd/yyyy"
                                        ) === "01/01/0001"
                                          ? ""
                                          : formatDate(
                                              new Date(item.brthDt),
                                              "MM/dd/yyyy"
                                            )}
                                      </td>
                                      <td>
                                        {formatDate(
                                          new Date(item.dthDt),
                                          "MM/dd/yyyy"
                                        ) === "01/01/0001"
                                          ? ""
                                          : formatDate(
                                              new Date(item.dthDt),
                                              "MM/dd/yyyy"
                                            )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </PDFExport>
              )}
            </div>
          </>
        </Modal>
      </div>
      {
        <style>
          {`
            .css-1gvnyrw-MuiInputBase-root-MuiOutlinedInput-root{
                 padding-right: 0px;
            }
                 .css-q2lp9a-MuiInputBase-root-MuiOutlinedInput-root{
                 padding-right: 0px;
                 }
            `}
        </style>
      }
    </div>
  );
};
export default TbGrid;
