import * as React from "react";
import { customToken } from "./customToken";
import * as ReactDOM from "react-dom";
import { useState, useEffect, useRef, useMemo } from "react";

import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import { process } from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { Button as ButtonKendo } from "@progress/kendo-react-buttons";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import axios from "axios";
import { ResponsiveContainer } from "recharts";
import { ExcelExportColumnGroup } from "@progress/kendo-react-excel-export";

import Modal from "react-bootstrap/Modal";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import Moment from 'react-moment';
import Enumerable from "linq";
import "hammerjs";
import { Button } from "react-bootstrap";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import {
  FaCartPlus,
  FaFileExcel,
  FaFilePdf,
  FaMinus,
  FaMinusCircle,
  FaPlus,
  FaPlusCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
//import BankLogoPage from './bankLogoPage';
const CustomGroupHeader = (props) => {
  return `${props.value}`;
};
const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "1px",

          width: "98%",
          borderBottom: "1px solid #bce8f1",
        }}
      >
        <div className="row d-flex mx-3">
          {/* <div className='col text-start'>
            <a className='px-2'  ><BankLogoPage /></a>

          </div> */}
          <div className="col text-end px-5 py-2">
            <h2 className="fw-bold text-fitek">Time Weighted Return Report </h2>
          </div>
        </div>

        {/* <div className='fw-bold  text-center'>
                  Account Profile</div> */}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          //  borderTop: "1px solid #bce8f1"
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};

const chartBootstrapV4Colors = [
  "#006fba",
  "#0C483E",
  "#00AB8D",
  "#65697D",
  "#D8DAE5",
  "#9E8AA3",
  "#D38BB2",
  "#7EF58B",
  "#E78B8B",
];

const initialGroup = [
  {
    field: "groupHeader",
  },
];

const processWithGroups = (data, dataState) => {
  //
  //const groups = dataState.group;
  const filesDocArray = [];

  // if (groups) {
  //   groups.map((group) => (group.aggregates = aggregates));
  // }

  // dataState.group = groups;
  const newDataState = process(data, dataState);
  // setGroupIds({
  //   data: newDataState.data,
  //   group: dataState.group,
  // });
  return newDataState;
};

const DailyPerformenceGrid = ({
  data,
  flag,
  alloc,
  InceptnDt,
  triggerClearfilter,
}) => {
  console.log(alloc);
  const [colmenu, setColmenu] = useState(true);
  // const salesData = [20, 40, 45, 30, 50];
  //const purchaseData = [12, 30, 30, 45, 10];
  var lable1 = "";
  var lable2 = "";
  const categories = [
    "MTD",
    "QTD",
    "YTD",
    "1 Year",
    "3 Year",
    "5 Year",
    "10 Year",
    "Inception",
  ];

  var arr1 = [];
  var arr2 = [];
  for (var i = 0; i < alloc.length; i++) {
    if (i % 2 == 0) {
      arr1.push(alloc[i].retOneMonth);
      arr1.push(alloc[i].retThreeMonth);
      arr1.push(alloc[i].retYTD);
      arr1.push(alloc[i].retOneYear);
      arr1.push(alloc[i].retThreeYear);
      arr1.push(alloc[i].retFiveYear);
      arr1.push(alloc[i].retTenYear);
      arr1.push(alloc[i].retInception);
      lable1 = alloc[i].categoryNm;
    } else {
      arr2.push(alloc[i].retOneMonth);
      arr2.push(alloc[i].retThreeMonth);
      arr2.push(alloc[i].retYTD);
      arr2.push(alloc[i].retOneYear);
      arr2.push(alloc[i].retThreeYear);
      arr2.push(alloc[i].retInception);
      lable2 = alloc[i].categoryNm;
    }
  }

  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };
  const _export = React.useRef(null);

  const dataExport = process(data, {
    // group: initialGroup,
  }).data;
  const [locked, setLocked] = React.useState(false);
  const [expandedState, setExpandedState] = React.useState(true);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const columnLocked = () => {
    setLocked(!locked);
  };

  const RightNameHeader = (props) => {
    return (
      <a
        className="k-link"
        style={{
          float: "right",
        }}
        onClick={props.onClick}
      >
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={
            {
              // color: "#53d2fa",
            }
          }
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };

  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    for (var i = 0; i < newColumnsState.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        if (newColumnsState[i].field === columns[j].field)
          newColumnsState[i].headerClassName = columns[j].headerClassName;
      }
    }
    // newColumnsState[0].show = localStorage.getItem("SelAcctId")>0?false:true  ;
    // newColumnsState[0].columnMenu = menuWithExcelCheck;
    // newColumnsState[1].columnMenu = menuWithExcelCheck;
    // newColumnsState[2].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[3].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[4].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[6].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[7].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[8].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[9].columnMenu = menuWithoutExcelCheck;

    return newColumnsState;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const themes = useSelector(selectTheme);
  const defaultColumns = [
    {
      title: "Asset Class",
      field: "categoryNm",
      minWidth: "220px",
      show: true,
      filter: "text",
      locked: false,
      //headerClassName: 'rightHeader'
    },

    {
      title: "Market Value",
      field: "market",
      minWidth: "150px",
      show: true,
      filter: "numeric",
      locked: false,
      //locked: true,
      headerClassName: "rightHeader",
    },
    {
      title: "1 Month",
      field: "retOneMonth",

      show: true,
      filter: "numeric",
      minWidth: "auto",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "3 Months",
      field: "retThreeMonth",

      show: true,
      filter: "numeric",
      minWidth: "auto",
      locked: false,

      headerClassName: "rightHeader",
    },
    {
      title: "YTD",
      field: "retYTD",

      show: true,
      filter: "numeric",
      minWidth: "auto",
      locked: false,

      headerClassName: "rightHeader",
    },
    // {
    //   title: '1 Mo',
    //   field: 'retOneMonth',
    //   minWidth: 'auto',
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,

    //   headerClassName: 'rightHeader'
    // },
    // {
    //   title: '3 Mo',
    //   field: 'retThreeMonth',
    //   minWidth: 'auto',
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,

    //   headerClassName: 'rightHeader'
    // },
    {
      title: "1 Yr",
      field: "retOneYear",

      show: true,
      filter: "numeric",
      locked: false,
      minWidth: "auto",

      headerClassName: "rightHeader",
    },
    {
      title: "3 Yrs",
      field: "retThreeYear",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,

      headerClassName: "rightHeader",
    },
    {
      title: "5 Yrs",
      field: "retFiveYear",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,

      headerClassName: "rightHeader",
    },
    {
      title: "10 Yrs",
      field: "retTenYear",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,

      headerClassName: "rightHeader",
    },
    {
      title: "",
      field: "retInception",
      minWidth: "150px",
      show: true,
      filter: "numeric",
      locked: false,
      minWidth: "130px",
      headerClassName: "rightHeader",
    },
  ];

  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 1000,
    skip: 0,
    // sort: [{ field: 'account', dir: 'asc' }],
    // group: [{ field: 'groupHeader' }],
  });

  let initialStateExcel = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    // sort: [{ field: 'account', dir: 'asc' }],
    // group: [{ field: 'groupHeader' }],
  });

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );
  const [columnLatest, setColumnLatest] = React.useState(null);

  const [resultExcel, setResultExcel] = React.useState(
    processWithGroups(data, initialStateExcel.dataState)
  );

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const [dataState, setDataState] = React.useState(initialState.dataState);
  let loadedColumns = localStorage.getItem("gridColumns");

  //const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;
  const GridColumns = defaultColumns;
  // GridColumns[0].show = localStorage.getItem("SelAcctId")>0?false:true  ;
  // GridColumns[0].columnMenu = menuWithExcelCheck;
  // GridColumns[1].columnMenu = menuWithExcelCheck;
  // GridColumns[2].columnMenu = menuWithoutExcelCheck;
  // GridColumns[3].columnMenu = menuWithoutExcelCheck;
  // GridColumns[4].columnMenu = menuWithoutExcelCheck;
  // GridColumns[5].columnMenu = menuWithoutExcelCheck;
  // GridColumns[6].columnMenu = menuWithoutExcelCheck;
  // GridColumns[7].columnMenu = menuWithoutExcelCheck;
  // GridColumns[8].columnMenu = menuWithoutExcelCheck;
  // GridColumns[9].columnMenu = menuWithoutExcelCheck;

  GridColumns[9].title = "Inception\n(" + InceptnDt + ")";
  const [stateColumns, setStateColumns] = React.useState(GridColumns);

  let pageSize = 20;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });

  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 33; //let 1 for Portfoliholdings Grid
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData, config)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };
  const saveColumnsState = (columns) => {
    // console.log('save called!!');
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);

  const columnReorder = (event) => {
    setColumnLatest(event.target._columns);
  };

  const dataStateChange = (event) => {
    if (event.dataState.filter !== undefined) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }

    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumns.length; j++) {
        if (stateColumns[i].field === defaultColumns[j].field)
          stateColumns[i].headerClassName =
            defaultColumns[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }

    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });

    setResult(processWithGroups(data, updatedState.dataState));
    setResultExcel(processWithGroups(data, updatedStateExcel.dataState));

    setDataState(updatedState.dataState);
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    // setResult({ ...result.data });
    setResult({
      ...result,
      data: [...result.data],
    });
    //data: [...result.data],
  };
  const handleColapse = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = false;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(false);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  const handleExpand = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = true;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(true);
    setResult({
      ...result,
      data: [...result.data],
    });
  };
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      // if (column.aggregate) {
      //   cells.push(
      //     <td style={{ textAlign: 'right' }} >
      //       {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.00')}
      //     </td>
      //   );
      // } else {
      cells.push(<td>&nbsp;</td>);
      //}
    });
    return cells;
  };

  const cellRender = (tdElement, cellProps) => {
    //

    // if (
    //   //cellProps.rowType === 'groupHeader' &&
    //   tdElement &&
    //   tdElement.props.role != 'presentation'
    // ) {
    //   //IMPORTANT - You need to add collection with the columns and their field name
    //   //you can define the Grid columns outside of the Grid and reuse them here.
    //   const columns = [

    //     { field: 'categoryNm' },
    //     { field: 'market' },
    //     { field: 'retOneMonth' },
    //     { field: 'retThreeMonth' },
    //     { field: 'retYTD' },
    //     { field: 'retOneYear' },
    //     { field: 'retThreeYear' },
    //     { field: 'retInception' },

    //   ];

    //   return (
    //     <>
    //       <td
    //         {...tdElement.props}
    //         colSpan={tdElement.props.colSpan - columns.length}
    //       ></td>
    //       {getCells(columns, cellProps)}
    //     </>
    //   );
    // }

    if (cellProps.rowType === "data") {
      if (cellProps.field === "market") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "retOneMonth") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "retThreeMonth") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "retYTD") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "retOneYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "retThreeYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "retFiveYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "retTenYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "retThreeMonth") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "retOneMonth") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "retInception") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }

    return tdElement;
  };

  const [allocData, setAllocData] = React.useState(alloc);

  // const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
  // const labelContent = (e) => `${e.value.toFixed(2)}`;
  let allPDFExport;
  let gridPDFExport = [];
  let gridPDFExportcopy = [];

  const ChangeLineChart = (e) => {
    var actId = e.dataItem.acctId;

    var acctData = Enumerable.from(alloc)
      .where((w) => w.account === actId)
      .toArray();

    setAllocData(acctData);
    setDataState(dataState);
  };

  const showpreview = async () => {
    setShow(true);
  };

  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };

  const exportPDFWithMethod = () => {
    let gridElement = document.querySelector(".k-grid");
    drawDOM(gridElement, {
      paperSize: "A4",
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        // console.log(dataUri.split(";base64,")[1]);
        gridPDFExport[0] = dataUri;
      });
  };

  useMemo(() => {
    if (triggerClearfilter) {
      debugger;
      let updatedState = createDataState({ ...dataState, filter: null }); //here the filter object is set to null
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
      for (var i = 0; i < stateColumns.length; i++) {
        if (stateColumns[i].headerClassName != undefined)
          stateColumns[i].headerClassName = stateColumns[
            i
          ].headerClassName.replaceAll("active", "");
      }
      console.log(dataState);
    }
  }, [triggerClearfilter]);

  var excelTitle =
    localStorage.getItem("SelAcctId") == 0
      ? "Consolidation:" + localStorage.getItem("pConsName")
      : "Account Number:" + localStorage.getItem("SelAcctNm");
  const grid = (
    <Grid
      // style={{ height: 'auto' , width: "100%",}}
      //style={{  width: "auto", position: 'relative',  border: 'none' }}
      data={result}
      {...dataState}
      onDataStateChange={dataStateChange}
      onColumnReorder={onColumnReorder}
      onColumnResize={onColumnResize}
      expandField="expanded"
      onExpandChange={expandChange}
      sortable={true}
      resizable={true}
      reorderable={true}
      pageable={false}
      pageSize={1000}
      //group={[{field:'groupHeader'}]}
      // skip={page.skip}
      // pageable={{
      //   pageSizes: true,
      // }}
      // pageSize={page.take}
      // total={data.length}
      // groupable={{
      //   footer: 'always',
      //   enabled: false
      // }}
      cellRender={cellRender}
      //onRowClick={ChangeLineChart}
    >
      {/* <GridToolbar> */}
      {/* <div className="col text-start">
          {
            expandedState?
            <> <Button sx={{ textTransform: 'none' }} variant="outlined" size='small' onClick={handleColapse}  ><FaMinus className='mx-2'></FaMinus></Button></>
            :
            <> <Button sx={{ textTransform: 'none' }} variant="outlined" size='small' onClick={handleExpand} ><FaPlus className='mx-2'></FaPlus></Button></>
          }
          </div> */}
      {/* <ButtonKendo className="buttons-container-button" icon="excel" onClick={excelExport}>
          Export
        </ButtonKendo> */}

      {/* </GridToolbar> */}
      {stateColumns.map(
        (column, idx) =>
          column.show && (
            <Column
              width={setWidth(column.minWidth)}
              // locked={column.locked}
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              // footerCell={column.footerCell}
              // cell={NumberCell}
              // headerCell={column.headerCell}
              headerClassName={column.headerClassName}
              {...column}
              columnMenu={(props) => (
                <>
                  {column.filter === "text" ? (
                    <CustomColumnMenuNoGrpChkBox
                      {...props}
                      columns={stateColumns}
                      data={data}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  ) : (
                    <CustomColumnMenuNoGrp
                      {...props}
                      columns={stateColumns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                </>
              )}

              // columnMenu={(props) => (
              //   <CustomColumnMenuNoGrp
              //     {...props}
              //     columns={stateColumns}
              //     onColumnsSubmit={onColumnsSubmit}

              //   />
              // )}
            />
          )
      )}
    </Grid>
  );

  const chart = (
    <ResponsiveContainer>
      <Chart seriesColors={chartBootstrapV4Colors} style={{ height: "370px" }}>
        <ChartLegend position="bottom" />
        <ChartSeriesDefaults
          type="column"
          // labels={{
          //   format: "c",
          // }}
        />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} />
        </ChartCategoryAxis>
        <ChartTooltip />
        <ChartSeries>
          <ChartSeriesItem data={arr1} name={lable1} />
          <ChartSeriesItem data={arr2} name={lable2} />
        </ChartSeries>
      </Chart>
    </ResponsiveContainer>
  );

  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const [btnStatus, setBtnStatus] = React.useState(true);
  return (
    <div>
      <div className="card-header row d-flex justify-content-between align-items-center">
        {/* <div className="col text-end">
          <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
            <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
            <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem>

          </DropdownButton>

        </div> */}
        {/* <div className="col">
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={excelExport}
          >
            Export to Excel
          </button>
          &nbsp;
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={showpreview}
          >
          Preview
          </button>
         
        </div> */}
      </div>

      {/* <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Header closeButton><div className="row w-100 tableheader">
          <div className='col'>
            <Modal.Title>Time Weighted Return For Selected Periods - Pdf Preview</Modal.Title>
          </div>
          <div className='col text-end'>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={printPDF}
            >
              Print
            </button>
          </div>

        </div>
        </Modal.Header>
        <Modal.Body>
          <PDFExport
            margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
            pageTemplate={PageTemplate}
            forcePageBreak=".page-break"
            paperSize="A4"
            scale={0.5}
            allPages={true}
            //margin="1cm"
            landscape={true}
            repeatHeaders={true}

            ref={(pdfExport) => (allPDFExport = pdfExport)}>
            <Grid
              style={{ height: 'auto' }}
              data={resultExcel}
              {...dataState}
              onDataStateChange={dataStateChange}
              onColumnReorder={onColumnReorder}
              onColumnResize={onColumnResize}
              expandField="expanded"
              onExpandChange={expandChange}
              sortable={true}
              resizable={false}
              reorderable={false}
              pageable={false}

             
              groupable={{
                footer: '',
                enabled: false
              }}
              cellRender={cellRender}
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      footerCell={column.footerCell}
                      
                      headerClassName={column.headerClassName}
                      {...column}

                    />
                  )
              )}
            </Grid>
            <br />
            <span className="page-break"></span>
            <Chart style={{ height: "550px" }}>
            
              <ChartLegend position="top" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    visible: true,
                    //rotation: 85,
                    format: "d",
                  }}
                //  categories={categoryAxis} 
                />
              </ChartCategoryAxis>
              <ChartTooltip  />

              <ChartSeries>
        <ChartSeriesItem
          name='3 Yr'
          type='column'
          data={allocData}
          categoryField="iobNm"
          field="threeYear"
          //aggregate='sum'
          labels={{
            visible: true,
            //content: labelContent,
          }}
        />
        <ChartSeriesItem
          name='5 Yr'
          type='column'
          data={allocData}
          categoryField="iobNm"
          field="fiveYear"
          //aggregate='sum'
          labels={{
            visible: true,
            //content: labelContent,
          }}
        />
        <ChartSeriesItem
          name='10 Yr'
          type="column"
          data={allocData}
          categoryField="iobNm"
          field="tenYear"
          //aggregate='sum'
          labels={{
            visible: true,
            //content: labelContent,
          }}
        />
        <ChartSeriesItem
          name='Inception'
          type='column'
          data={allocData}
          categoryField="iobNm"
          field="inception"
          //aggregate='sum'
          labels={{
            visible: true,
            //content: labelContent,
          }}
        />
      </ChartSeries>
            </Chart>
          </PDFExport>
        </Modal.Body>
        <Modal.Footer>

          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>

        </Modal.Footer>

      </Modal> */}

      <div className="card-body">
        <div className="mx-1 px-1 my-1 py-1">
          <ExcelExport
            data={result.data}
            group={initialGroup}
            ref={_export}
            fileName={excelTitle + "_Performance.xlsx"}
          >
            <ExcelExportColumnGroup
              title={excelTitle}
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn
                field="groupHeader"
                hidden={true}
                groupHeader={CustomGroupHeader}
              />
              {stateColumns.map((w) => {
                return (
                  <ExcelExportColumn
                    field={w.field}
                    title={w.title}
                    headerCellOptions={w.headerCellOptions}
                    cellOptions={w.headerCellOptions}
                  />
                );
              })}
              {/* <ExcelExportColumn field="detailedLine" title="Details" width={140} />
            <ExcelExportColumn field="monthToDate" title="Month to date (%)" width={150} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="quarterToDate" title="Quarter to date (%)" width={200} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }}/>
            <ExcelExportColumn field="yearToDate" title="Year to date (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="oneYear" title="Last 1 year (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="threeYear" title="Last 3 year (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="fiveYear" title="Last 5 year (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="tenYear" title="Last 10 year (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="inception" title="Since inception (%)" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} /> */}
            </ExcelExportColumnGroup>
          </ExcelExport>
          {grid}
        </div>
        {/* {grid} */}
      </div>

      {/* <br />
      <div className='row py-2'>
        <div className="col">
          <p className="tableheader h6">Total Account Vs Benchmark</p>
        </div>
        <div className='card'>
          <div className='card-body'>

            {chart}

          </div>
        </div>

      </div> */}
      {themes === "dark" ? (
        <style>
          {`
      .k-grid .k-grouping-row .k-icon, .k-grid .k-grouping-row .k-svg-icon {
  
  color: white !important;
}
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}

.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
}`}
        </style>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DailyPerformenceGrid;
