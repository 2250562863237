import * as React from "react";
import { customToken } from "./customToken";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import Enumerable from "linq";
import { fipoBondByMinorCls } from "./cstmRptMain";
import { ResponsiveContainer } from "recharts";

const FipoMnrIndusSecChartWidget = () => {
  const chartDefaultV4Colors = [
    "#00876c",
    "#6e9b75",
    "#a5af90",
    "#cbc6b9",
    "#c9a47e",
    "#d07958",
  ];
  const [chartType, setChartType] = React.useState("pie");
  const [selAcct, SetselAcct] = React.useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );

  let table4 = [];
  table4 = Enumerable.from(fipoBondByMinorCls)
    .where((w) => w.acctId === selAcct.acctId)
    .toArray();
  const labelContentMnr = (props) => {
    let formatedNumber = Number(props.dataItem.classPercent).toLocaleString(
      undefined,
      {
        style: "percent",
        minimumFractionDigits: 2,
      }
    );
    return `${props.category}  ${(props.dataItem.classPercent * 100).toFixed(
      2
    )}%`;
  };

  return (
    <div>
      <h5 className="text-fitek p-1">
        Bonds: By Asset Classification(Minor Class)
      </h5>
      <ResponsiveContainer className="page-break">
        {/* <div className="card-body">
        <div className="mx-1 my-1 py-1"> */}
        {table4.length === 0 ? (
          <div>No Data Found.</div>
        ) : (
          <Chart
            seriesColors={chartDefaultV4Colors}
            style={{ height: "400px" }}
          >
            {/* <ChartTitle text="Major Asset Chart" /> */}
            <ChartLegend position="bottom" />

            <ChartSeries>
              <ChartSeriesItem
                type={chartType}
                data={table4}
                autoFit={true}
                field="classPercent"
                categoryField="minorClass"
                labels={{
                  visible: true,
                  content: labelContentMnr,
                }}
              />
            </ChartSeries>
          </Chart>
        )}

        {/* </div>
      </div> */}
      </ResponsiveContainer>
    </div>
  );
};
export default FipoMnrIndusSecChartWidget;
