import * as React from "react";
import { customToken } from "./customToken";
import * as ReactDOM from "react-dom";
import { useState, useEffect, useRef, useMemo } from "react";

import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import { process } from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { Button as ButtonKendo } from "@progress/kendo-react-buttons";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import axios from "axios";
import { ResponsiveContainer } from "recharts";
import { ExcelExportColumnGroup } from "@progress/kendo-react-excel-export";

import { Circles, ColorRing } from "react-loader-spinner";
import { MdFilterAltOff } from "react-icons/md";
import { Dropdown, Menu } from "antd";

import Modal from "react-bootstrap/Modal";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { filterBy } from "@progress/kendo-data-query";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartArea,
} from "@progress/kendo-react-charts";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Enumerable from "linq";
import "hammerjs";
import { Button } from "react-bootstrap";
import { CustomColumnMenuNoGrpChkBox } from "./customColumnMenuNoGrpChkBox";
import {
  FaCartPlus,
  FaFileExcel,
  FaFilePdf,
  FaMinus,
  FaMinusCircle,
  FaPlus,
  FaPlusCircle,
  FaCog,
  FaChevronDown,
} from "react-icons/fa";
import { Card } from "@mui/material";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/theme/themeSlice";
import { Viewer } from "@grapecity/activereports-react";

//import BankLogoPage from './bankLogoPage';
const CustomGroupHeader = (props) => {
  return `${props.value}`;
};
const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "1px",

          width: "98%",
          borderBottom: "1px solid #bce8f1",
        }}
      >
        <div className="row d-flex mx-3">
          {/* <div className='col text-start'>
            <a className='px-2'  ><BankLogoPage /></a>

          </div> */}
          <div className="col text-end px-5 py-2">
            <h2 className="fw-bold text-fitek">Time Weighted Return Report </h2>
          </div>
        </div>

        {/* <div className='fw-bold  text-center'>
                  Account Profile</div> */}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          //  borderTop: "1px solid #bce8f1"
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};

const chartBootstrapV4Colors = ["#778798", "#3a444d", "#bbc3cb", "#576574"];

const aggregates = [
  {
    field: "quarterToDate",
    aggregate: "sum",
  },
  {
    field: "yearToDate",
    aggregate: "sum",
  },
  {
    field: "oneYear",
    aggregate: "sum",
  },
  {
    field: "threeYear",
    aggregate: "sum",
  },
  {
    field: "fiveYear",
    aggregate: "sum",
  },
  {
    field: "tenYear",
    aggregate: "sum",
  },
  {
    field: "inception",
    aggregate: "sum",
  },
];

const initialGroup = [
  {
    field: "groupHeader",
  },
];

const processWithGroups = (data, dataState) => {
  //
  const groups = dataState.group;
  const filesDocArray = [];

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const TimeWtdRtnRptGrid = ({
  data,
  flag,
  alloc,
  InceptnDt,
  reportData,
  cardValues,
}) => {
  const themes = useSelector(selectTheme);
  // console.log("data", data);

  const [clearing, setClearing] = useState(false);
  const [triggerClearfilter, setTriggerClearfilter] = useState(0);

  const menuWithExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };

  const menuWithoutExcelCheck = (props) => {
    return (
      <div>
        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>
    );
  };
  const _export = React.useRef(null);
  const viewerRef = React.useRef();

  const dataExport = process(data, {
    group: initialGroup,
  }).data;
  const [locked, setLocked] = React.useState(false);
  const [expandedState, setExpandedState] = React.useState(true);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [columnLatest, setColumnLatest] = React.useState(null);
  const columnLocked = () => {
    setLocked(!locked);
  };

  const totalSum = (props) => {
    const field = props.field || "";

    //alert('AAA');
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "##,#.00")}
      </td>
    );
  };

  const RightNameHeader = (props) => {
    return (
      <a
        className="k-link"
        style={{
          float: "right",
        }}
        onClick={props.onClick}
      >
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={
            {
              // color: "#53d2fa",
            }
          }
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };

  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));

    setColumnLatest(props.target._columns);
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    for (var i = 0; i < newColumnsState.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        if (newColumnsState[i].field === columns[j].field)
          newColumnsState[i].headerClassName = columns[j].headerClassName;
      }
    }

    //newColumnsState[6].footerCell = totalSum;
    //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
    var tempcolumn = [];

    var j = 0;
    for (var i = 0; i < newColumnsState.length; i++) {
      i =
        newColumnsState[i].field === "name" &&
        localStorage.getItem("SelAcctId") > 0
          ? i + 1
          : i;
      tempcolumn[j] = newColumnsState[i];

      j++;
    }

    return tempcolumn;
    const [, ...restnew] = newColumnsState;
    //newColumnsState[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;
    if (
      localStorage.getItem("SelAcctId") > 0 &&
      newColumnsState[0].field === "accountName"
    ) {
      return restnew;
    } else {
      return newColumnsState;
    }

    // newColumnsState[0].columnMenu = menuWithExcelCheck;
    // newColumnsState[1].columnMenu = menuWithExcelCheck;
    // newColumnsState[2].columnMenu = menuWithExcelCheck;
    // newColumnsState[3].columnMenu = menuWithExcelCheck;
    // newColumnsState[4].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[5].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[6].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[7].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[8].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[9].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[10].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[11].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[12].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[13].columnMenu = menuWithExcelCheck;
    // newColumnsState[14].columnMenu = menuWithExcelCheck;
    // newColumnsState[15].columnMenu = menuWithExcelCheck;
    // newColumnsState[16].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[17].columnMenu = menuWithExcelCheck;
    // newColumnsState[18].columnMenu = menuWithExcelCheck;
    // newColumnsState[19].columnMenu = menuWithExcelCheck;
    // newColumnsState[20].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[21].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[22].columnMenu = menuWithExcelCheck;
    // newColumnsState[23].columnMenu = menuWithExcelCheck;
    // newColumnsState[24].columnMenu = menuWithExcelCheck;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const defaultColumns = [
    // {
    //   title: 'Account Number',
    //   field: 'account',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Account#",
      field: "name",
      minWidth: 230,
      //show: true,
      filter: "text",
      locked: false,
      orderIndex: -1,
    },
    // {
    //   title: 'Performance Template',
    //   field: 'groupHeader',
    //   minWidth: 200,
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: "Details",
      field: "detailedLine",
      minWidth: 240,
      show: true,
      filter: "text",
      orderIndex: 0,
      //locked: true,
    },
    {
      title: "MTD",
      field: "monthToDate",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },

      headerClassName: "rightHeader",
      orderIndex: 1,
    },
    {
      title: "QTD",
      field: "quarterToDate",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      // footerCell: totalSum,
      headerClassName: "rightHeader",
      orderIndex: 2,
    },
    {
      title: "YTD",
      field: "yearToDate",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      // footerCell: totalSum,
      headerClassName: "rightHeader",
      orderIndex: 3,
    },
    {
      title: "1 Yr",
      field: "oneYear",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      //footerCell: totalSum,
      headerClassName: "rightHeader",
      orderIndex: 4,
    },
    {
      title: "3 Yrs",
      field: "threeYear",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      // footerCell: totalSum,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      headerClassName: "rightHeader",
      orderIndex: 5,
    },
    {
      title: "5 Yrs",
      field: "fiveYear",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      // footerCell: totalSum,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      headerClassName: "rightHeader",
      orderIndex: 6,
    },
    {
      title: "10 Yrs",
      field: "tenYear",
      minWidth: "auto",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      headerClassName: "rightHeader",
      orderIndex: 7,
    },
    {
      title: "",
      field: "inception",
      minWidth: "150px",
      show: true,
      filter: "numeric",
      locked: false,
      //footerCell: totalSum,
      headerCellOptions: { textAlign: "right" },
      cellOptions: { format: "#,##0.00" },
      headerClassName: "rightHeader",
      orderIndex: 8,
    },
  ];

  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    sort: [{ field: "account", dir: "asc" }],
    group: [{ field: "groupHeader" }],
  });

  let initialStateExcel = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    sort: [{ field: "account", dir: "asc" }],
    group: [{ field: "groupHeader" }],
  });

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );

  const [resultExcel, setResultExcel] = React.useState(
    processWithGroups(data, initialStateExcel.dataState)
  );

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  // const bankName = process.env.REACT_APP_BANK;
  // const bankName = "Demo";

  // console.log("bankName", bankName);

  const bankName = process.env.REACT_APP_BANK;
  // const bankName = "Demo";

  console.log("bankName", bankName);

  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch("Performance.rdlx-json");

    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    const report = await loadReport();

    report.ReportSections[0].PageFooter.ReportItems[0].Value =
      bankName + ".png";

    

    // for(let i=0;i<reportData.t1.length;i++){
    //   reportData.t1[i].mdlWegh = parseNumber(formatNumber( reportData.t1[i].mdlWegh,"n2"));
    // }
    // console.log("before logo value", report.ReportSections[0].PageFooter.ReportItems[0].Value)

    // report.ReportSections[0].PageFooter.ReportItems[0].Value = bankName + ".png";

    // console.log("after logo value", report.ReportSections[0].PageFooter.ReportItems[0].Value)

   
    debugger;
    reportData.lstRTTimeWtdReturnT1 = filterBy(data, dataState.filter);
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(reportData);

    /////////////////////
    let parameters = [
      {
        Name: "pInceptionDate",
        Value: [InceptnDt == "" ? "01/01/1824" : InceptnDt],
      },
      {
        Name: "pConsName",
        Value: [
          localStorage.getItem("pConsName") == "" ||
          localStorage.getItem("pConsName") == null
            ? 0
            : localStorage.getItem("pConsName"),
        ],
      },
      {
        Name: "pAccName",
        Value: [
          localStorage.getItem("SelAcctNm") == "" ||
          localStorage.getItem("SelAcctNm") == null
            ? 0
            : localStorage.getItem("SelAcctNm"),
        ],
        //
      },
      {
        Name: "pShow",
        Value: [
          localStorage.getItem("SelAcctId") == "0" ? 1 : 0,

          // localStorage.getItem("pConsId") == "0"
          //   ? 0
          //   : localStorage.getItem("SelAcctNm"),
        ],
        // Name: "pConsName",
        // Value: [
        //   localStorage.getItem("pConsName") == ""
        //     ? 0
        //     : localStorage.getItem("pConsName"),
        // ],
      },
    ];

    /////////////////////

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  const handlePreview = () => {
    setShowModal(true);
    openReport();
  };

  const [dataState, setDataState] = React.useState(initialState.dataState);
  let loadedColumns = localStorage.getItem("gridColumns");

  //const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;
  const GridColumns = defaultColumns;
  GridColumns[0].show = localStorage.getItem("SelAcctId") > 0 ? false : true;

  // GridColumns[9].title = "Inception\n(" + InceptnDt + ")";
  GridColumns[9].title =
    InceptnDt == "" ? "Inception" : "Inception\n(" + InceptnDt + ")";
  // GridColumns[0].columnMenu = menuWithExcelCheck;
  // GridColumns[1].columnMenu = menuWithExcelCheck;
  // GridColumns[2].columnMenu = menuWithoutExcelCheck;
  // GridColumns[3].columnMenu = menuWithoutExcelCheck;
  // GridColumns[4].columnMenu = menuWithoutExcelCheck;
  // GridColumns[5].columnMenu = menuWithoutExcelCheck;
  // GridColumns[6].columnMenu = menuWithoutExcelCheck;
  // GridColumns[7].columnMenu = menuWithoutExcelCheck;
  // GridColumns[8].columnMenu = menuWithoutExcelCheck;
  // GridColumns[9].columnMenu = menuWithoutExcelCheck;
  const [, ...rest] = GridColumns;
  const [stateColumns, setStateColumns] = React.useState(
    localStorage.getItem("SelAcctId") > 0 ? rest : GridColumns
  );

  let pageSize = 20;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });

  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 33; //let 1 for Portfoliholdings Grid
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
     
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData, config)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        saveColumnStateDb();
      })
      .catch((error) => {
        //

        console.log("my error is " + error);
      });
  };
  const saveColumnsState = (columns) => {
    // console.log('save called!!');
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };

  const dataStateChange = (event) => {
    if (
      event.dataState.filter !== undefined &&
      event.dataState.filter !== null
    ) {
      if (event.dataState.filter.filters.length > 0) {
        for (var i = 0; i < stateColumns.length; i++) {
          for (var j = 0; j < event.dataState.filter.filters.length; j++) {
            if (
              stateColumns[i].field ==
              event.dataState.filter.filters[j].filters[0].field
            ) {
              stateColumns[i].headerClassName = "active";
              break;
            } else {
              stateColumns[i].headerClassName = "";
            }
          }
        }
      } else {
        for (var i = 0; i < stateColumns.length; i++) {
          stateColumns[i].headerClassName = "";
        }
      }
    } else {
      for (var i = 0; i < stateColumns.length; i++) {
        stateColumns[i].headerClassName = "";
      }
    }

    for (var i = 0; i < stateColumns.length; i++) {
      for (var j = 0; j < defaultColumns.length; j++) {
        if (stateColumns[i].field === defaultColumns[j].field)
          stateColumns[i].headerClassName =
            defaultColumns[j].headerClassName +
            " " +
            stateColumns[i].headerClassName;
      }
    }
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });

    setResult(processWithGroups(data, updatedState.dataState));
    setResultExcel(processWithGroups(data, updatedStateExcel.dataState));

    setDataState(updatedState.dataState);
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    // setResult({ ...result.data });
    setResult({
      ...result,
      data: [...result.data],
    });
    //data: [...result.data],
  };
  const handleColapse = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = false;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(false);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  const handleExpand = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = true;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(true);
    setResult({
      ...result,
      data: [...result.data],
    });
  };
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(
              cellProps.dataItem.aggregates[column.field][column.aggregate],
              "##,#.00"
            )}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };

  const handleclearfilter = (event) => {
    // event?.preventDefault();
    setTriggerClearfilter((triggerClearfilter) => triggerClearfilter + 1);
    // console.log("clear filter", triggerColSave);
    setClearing(true);
    setTimeout(() => {
      setClearing(false);
    }, 1400);
  };

  const cellRender = (tdElement, cellProps) => {
    //
    if (
      cellProps.rowType === "groupHeader" &&
      tdElement &&
      tdElement.props.role != "presentation"
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.
      const columns = [
        // { field: 'account' },
        // { field: 'accountName' },
        //{ field: 'groupHeader' }, ////////
        { field: "detailedLine" },
        { field: "monthToDate" },
        { field: "quarterToDate" },
        { field: "yearToDate" },
        { field: "oneYear" },
        { field: "threeYear" },
        { field: "fiveYear" },
        { field: "tenYear" },
        { field: "inception" },
      ];

      if (!tdElement.props.colSpan) {
        return <td></td>;
      }
      let currentColumns = stateColumns.filter((c) => c.show);
      currentColumns = currentColumns.map((col) => {
        let aggrCol = columns.find((c) => {
          return c.field == col.field;
        });
        if (aggrCol && aggrCol.aggregate) {
          return {
            ...col,
            aggregate: aggrCol.aggregate,
          };
        } else {
          return col;
        }
      });
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - currentColumns.length}
          ></td>
          {getCells(
            currentColumns.sort((a, b) => {
              return a.orderIndex - b.orderIndex;
            }),
            cellProps
          )}
        </>
      );
    }
    if (cellProps.rowType === "groupFooter") {
      if (cellProps.field === "quarterToDate") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.quarterToDate.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "yearToDate") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.yearToDate.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "quarterToDate") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.quarterToDate.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "oneYear") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem.aggregates.oneYear.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "threeYear") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.threeYear.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "fiveYear") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.fiveYear.sum,
              "##,#.00"
            )}
          </td>
        );
      }
      if (cellProps.field === "tenYear") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem.aggregates.tenYear.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "inception") {
        return (
          <td
            style={{ textAlign: "right" }}
            aria-colindex={cellProps.columnIndex}
            role={"gridcell"}
          >
            {formatNumber(
              cellProps.dataItem.aggregates.inception.sum,
              "##,#.00"
            )}
          </td>
        );
      }
    }
    if (cellProps.rowType === "data") {
      if (cellProps.field === "monthToDate") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "quarterToDate") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "yearToDate") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "oneYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "threeYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "fiveYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "tenYear") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "inception") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {cellProps.dataItem[cellProps.field] == 0
              ? ""
              : formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }

    return tdElement;
  };

  useMemo(() => {
    if (triggerClearfilter) {
      let updatedState = createDataState({ ...dataState, filter: null }); //here the filter object is set to null
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
      for (var i = 0; i < stateColumns.length; i++) {
        if (stateColumns[i].headerClassName != undefined)
          stateColumns[i].headerClassName = stateColumns[
            i
          ].headerClassName.replaceAll("active", "");
      }
    
    }
  }, [triggerClearfilter]);

  const [allocData, setAllocData] = React.useState(alloc);

  // const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
  // const labelContent = (e) => `${e.value.toFixed(2)}`;
  let allPDFExport;
  let gridPDFExport = [];
  let gridPDFExportcopy = [];

  const ChangeLineChart = (e) => {
    var actId = e.dataItem.acctId;

    var acctData = Enumerable.from(alloc)
      .where((w) => w.account === actId)
      .toArray();

    setAllocData(acctData);
    setDataState(dataState);
  };

  const showpreview = async () => {
    setShowModal(true);
  };

  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };

  const exportPDFWithMethod = () => {
    let gridElement = document.querySelector(".k-grid");
    drawDOM(gridElement, {
      paperSize: "A4",
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        // console.log(dataUri.split(";base64,")[1]);
        gridPDFExport[0] = dataUri;
      });
  };
  var excelTitle =
    localStorage.getItem("SelAcctId") == 0
      ? "Consolidation:" + localStorage.getItem("pConsName")
      : "Account Number:" + localStorage.getItem("SelAcctNm");

  const grid = (
    <>
      <Grid
        style={{ height: "650px" }}
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        onColumnReorder={onColumnReorder}
        onColumnResize={onColumnResize}
        expandField="expanded"
        onExpandChange={expandChange}
        sortable={true}
        resizable={true}
        reorderable={true}
        // pageable={true}
        // pageSize={1000}
        //group={[{field:'groupHeader'}]}
        // skip={page.skip}
        // pageable={{
        //   pageSizes: true,
        // }}
        // pageSize={page.take}
        // total={data.length}
        // groupable={{
        //   footer: 'always',
        //   enabled: false
        // }}
        cellRender={cellRender}
        //onRowClick={ChangeLineChart}
      >
        {/* <GridToolbar> */}
        {/* <div className="col text-start">
          {
            expandedState?
            <> <Button sx={{ textTransform: 'none' }} variant="outlined" size='small' onClick={handleColapse}  ><FaMinus className='mx-2'></FaMinus></Button></>
            :
            <> <Button sx={{ textTransform: 'none' }} variant="outlined" size='small' onClick={handleExpand} ><FaPlus className='mx-2'></FaPlus></Button></>
          }
          </div> */}
        {/* <ButtonKendo className="buttons-container-button" icon="excel" onClick={excelExport}>
          Export
        </ButtonKendo> */}

        {/* <div
            className="btn btn-outline-secondary btn-sm"
            onClick={excelExport}
          >
            <FaFileExcel></FaFileExcel>&nbsp;export
          </div>
          <div
            className="btn btn-outline-secondary btn-sm"
            onClick={handlePreview}
          >
            <FaFilePdf></FaFilePdf>&nbsp; pdf
          </div>
          <div>
            <button
              className="btn btn-outline-secondary align-items-center mx-2 btn-sm"
              onClick={handleclearfilter}
            >
              {clearing ? (
                <ColorRing
                  visible={true}
                  height="19"
                  width="19"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={[
                    "#4db8ff",
                    "#4db8ff",
                    "#4db8ff",
                    "#4db8ff",
                    "#4db8ff",
                  ]}
                />
              ) : (
                <MdFilterAltOff size={17}></MdFilterAltOff>
              )}{" "}
              clear filters
            </button>
          </div> */}
        {/* </GridToolbar> */}
        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <Column
                width={setWidth(column.minWidth)}
                locked={column.locked}
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                // footerCell={column.footerCell}
                // cell={NumberCell}
                // headerCell={column.headerCell}
                headerClassName={column.headerClassName}
                {...column}
                columnMenu={(props) => (
                  <>
                    {column.filter === "text" ? (
                      <CustomColumnMenuNoGrpChkBox
                        {...props}
                        columns={stateColumns}
                        data={data}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    ) : (
                      <CustomColumnMenuNoGrp
                        {...props}
                        columns={stateColumns}
                        onColumnsSubmit={onColumnsSubmit}
                      />
                    )}
                  </>
                )}
              />
            )
        )}
      </Grid>
    </>
  );

  const chart = (
    <ResponsiveContainer>
      <Chart seriesColors={chartBootstrapV4Colors} style={{ height: "550px" }}>
        {/* <ChartTitle text="Maturity Date Vs Call/Put Date" /> */}
        {/* <ChartTitle text="Returns (%)" /> */}
        <ChartArea
          background={themes === "dark" ? "#292929" : "white"}
          margin={30}
        />
        <ChartLegend
          position="top"
          inactiveItems={{
            labels: {
              color:
                themes === "dark"
                  ? "rgba(255, 255, 255, 0.43)"
                  : "rgba(0, 0, 0, 0.43)",
            },
          }}
          labels={{
            color: themes === "dark" ? "white" : "black",
          }}
        />
        <ChartValueAxis>
          <ChartValueAxisItem
            // title={{
            //     text: "Percentage",
            // }}
            labels={{
              visible: true,

              color: themes === "dark" ? "white" : "black",
            }}
            minorGridLines={{
              color: themes === "dark" ? "#e0e0e0" : "#7a7a7a",
            }}
            majorGridLines={{
              color: themes === "dark" ? "#e0e0e0" : "#7a7a7a",
            }}
          />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            minorGridLines={{
              color: themes === "dark" ? "#e0e0e0" : "#7a7a7a",
            }}
            majorGridLines={{
              color: themes === "dark" ? "#e0e0e0" : "#7a7a7a",
            }}
            labels={{
              visible: true,
              rotation: 45,
              format: "n2",
              color: themes === "dark" ? "white" : "black",
            }}
            //  categories={categoryAxis}
          />
        </ChartCategoryAxis>
        <ChartTooltip />
        <ChartSeries>
          <ChartSeriesItem
            name="3 Yrs"
            type="column"
            data={allocData}
            categoryField="iobNm"
            field="threeYear"
            //aggregate='sum'
            labels={{
              visible: true,
              format: "n2",
              //content: labelContent,
            }}
          />
          <ChartSeriesItem
            name="5 Yrs"
            type="column"
            data={allocData}
            categoryField="iobNm"
            field="fiveYear"
            //aggregate='sum'
            labels={{
              visible: true,
              format: "n2",
              //content: labelContent,
            }}
          />
          <ChartSeriesItem
            name="10 Yrs"
            type="column"
            data={allocData}
            categoryField="iobNm"
            field="tenYear"
            //aggregate='sum'
            labels={{
              visible: true,
              format: "n2",
              //content: labelContent,
            }}
          />
          <ChartSeriesItem
            name="Inception"
            type="column"
            data={allocData}
            categoryField="iobNm"
            field="inception"
            //aggregate='sum'
            labels={{
              visible: true,
              format: "n2",
              //content: labelContent,
            }}
          />
        </ChartSeries>
      </Chart>
    </ResponsiveContainer>
  );

  const [show, setShow] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const handleClose = () => setShowModal(false);
  const [btnStatus, setBtnStatus] = React.useState(true);
  var stateColExport = stateColumns;

  if (stateColExport.filter((x) => x.field !== "groupHeader")) {
    stateColExport = [
      {
        title: "",
        field: "groupHeader",
        groupHeader: CustomGroupHeader,
        show: false,
      },
      ...stateColExport,
    ];
  }
  stateColExport.sort(function (a, b) {
    return a.orderIndex - b.orderIndex;
  });
  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleClose}
        fullscreen
        size="xl"
        style={{ zIndex: 100000 }}
      >
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div>
        {" "}
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={handleclearfilter}>
                <MdFilterAltOff size={17} /> Clear Filters
              </Menu.Item>
              <Menu.Item onClick={excelExport}>
                <FaFileExcel /> Export to Excel
              </Menu.Item>
              <Menu.Item onClick={handlePreview}>
                <FaFilePdf /> Export to PDF
              </Menu.Item>
            </Menu>
          }
        >
          <div type="button" className="btn btn-outline-secondary btn-sm">
            <FaCog /> Table Options <FaChevronDown />
          </div>
        </Dropdown>
      </div>

      <div className="card-header row d-flex justify-content-between align-items-center my-2">
        <div className="col">
          <p className="tableheader h6">Account Segments</p>
        </div>

        {/* <div className="col text-end">
          <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
            <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
            <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem>

          </DropdownButton>

        </div> */}
        {/* <div className="col">
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={excelExport}
          >
            Export to Excel
          </button>
          &nbsp;
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={showpreview}
          >
          Preview
          </button>
         
        </div> */}
      </div>

      {/* <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Header closeButton><div className="row w-100 tableheader">
          <div className='col'>
            <Modal.Title>Time Weighted Return For Selected Periods - Pdf Preview</Modal.Title>
          </div>
          <div className='col text-end'>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={printPDF}
            >
              Print
            </button>
          </div>

        </div>
        </Modal.Header>
        <Modal.Body>
          <PDFExport
            margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
            pageTemplate={PageTemplate}
            forcePageBreak=".page-break"
            paperSize="A4"
            scale={0.5}
            allPages={true}
            //margin="1cm"
            landscape={true}
            repeatHeaders={true}

            ref={(pdfExport) => (allPDFExport = pdfExport)}>
            <Grid
              style={{ height: 'auto' }}
              data={resultExcel}
              {...dataState}
              onDataStateChange={dataStateChange}
              onColumnReorder={onColumnReorder}
              onColumnResize={onColumnResize}
              expandField="expanded"
              onExpandChange={expandChange}
              sortable={true}
              resizable={false}
              reorderable={false}
              pageable={false}

             
              groupable={{
                footer: '',
                enabled: false
              }}
              cellRender={cellRender}
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      footerCell={column.footerCell}
                      
                      headerClassName={column.headerClassName}
                      {...column}

                    />
                  )
              )}
            </Grid>
            <br />
            <span className="page-break"></span>
            <Chart style={{ height: "550px" }}>
            
              <ChartLegend position="top" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{
                    visible: true,
                    //rotation: 85,
                    format: "d",
                  }}
                //  categories={categoryAxis} 
                />
              </ChartCategoryAxis>
              <ChartTooltip  />

              <ChartSeries>
        <ChartSeriesItem
          name='3 Yr'
          type='column'
          data={allocData}
          categoryField="iobNm"
          field="threeYear"
          //aggregate='sum'
          labels={{
            visible: true,
            //content: labelContent,
          }}
        />
        <ChartSeriesItem
          name='5 Yr'
          type='column'
          data={allocData}
          categoryField="iobNm"
          field="fiveYear"
          //aggregate='sum'
          labels={{
            visible: true,
            //content: labelContent,
          }}
        />
        <ChartSeriesItem
          name='10 Yr'
          type="column"
          data={allocData}
          categoryField="iobNm"
          field="tenYear"
          //aggregate='sum'
          labels={{
            visible: true,
            //content: labelContent,
          }}
        />
        <ChartSeriesItem
          name='Inception'
          type='column'
          data={allocData}
          categoryField="iobNm"
          field="inception"
          //aggregate='sum'
          labels={{
            visible: true,
            //content: labelContent,
          }}
        />
      </ChartSeries>
            </Chart>
          </PDFExport>
        </Modal.Body>
        <Modal.Footer>

          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>

        </Modal.Footer>

      </Modal> */}

      <div className="card-body">
        <div className="mx-1 px-1 my-1 py-1">
          <ExcelExport
            data={result.data}
            group={initialGroup}
            ref={_export}
            fileName={excelTitle + "_Performance.xlsx"}
          >
            <ExcelExportColumnGroup
              title={excelTitle}
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumnGroup
                title={cardValues}
                headerCellOptions={{
                  textAlign: "left",
                }}
              >
                {stateColExport
                  .sort(function (a, b) {
                    return a.orderIndex - b.orderIndex;
                  })
                  .map((w) => {
                    return (
                      <ExcelExportColumn
                        field={w.field}
                        hidden={!w.show}
                        title={w.title}
                        groupHeader={w.groupHeader}
                        headerCellOptions={w.headerCellOptions}
                        cellOptions={w.cellOptions}
                      />
                    );
                  })}
              </ExcelExportColumnGroup>

              {/* <ExcelExportColumn
              field="groupHeader"
              hidden={true}
              groupHeader={CustomGroupHeader}
            />
            {localStorage.getItem("SelAcctId")>0?<></>:<ExcelExportColumn field="name" title="Account#" width={140} />}
            <ExcelExportColumn field="detailedLine" title="Details" width={140} />
            <ExcelExportColumn field="monthToDate" title="MTD" width={150} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="quarterToDate" title="QTD" width={200} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }}/>
            <ExcelExportColumn field="yearToDate" title="YTD" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="oneYear" title="1 Yr" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="threeYear" title="3 Yrs" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="fiveYear" title="5 Yrs" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="tenYear" title="10 Yrs" width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            <ExcelExportColumn field="inception" title={GridColumns[8].title} width={130} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} /> */}
            </ExcelExportColumnGroup>
          </ExcelExport>
          {grid}
        </div>
        {/* {grid} */}
      </div>

      <br />
      <div className="row py-2">
        <div className="col">
          <p className="tableheader h6">Total Account Vs Benchmark</p>
        </div>
        <Card>
          <div className="">
            <div className="">{chart}</div>
          </div>
        </Card>
      </div>
      {themes === "dark" ? (
        <style>
          {`
      .k-grid .k-grouping-row .k-icon, .k-grid .k-grouping-row .k-svg-icon {
  
  color: white !important;
}
      .k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  background-color: #292929;
  color: white;
}
.k-input-sm .k-input-button, .k-input-sm .k-spinner-increase, .k-input-sm .k-spinner-decrease, .k-picker-sm .k-input-button, .k-picker-sm .k-spinner-increase, .k-picker-sm .k-spinner-decrease {
  
  background-color: #686868 !important;
  color: white  !important;
  
}
  .k-grid-footer {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-footer td, .k-grid-footer .k-table-td {
  color:white !important;
  background-color: #292929 !important;
}
.k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
      .k-calendar-td:hover{
color: rgb(34, 34, 34);
}
.k-calendar-infinite .k-calendar-header {
  
  background-color: #1d1d1d !important;
 color: rgb(255, 255, 255);
}

.k-calendar-infinite .k-calendar-view {
  
  background-color: rgb(46, 46, 46);
  
}
.k-calendar .k-calendar-caption, .k-calendar .k-calendar-view th, .k-calendar .k-calendar-view .k-calendar-th, .k-calendar .k-meta-header, .k-calendar .k-month-header {
  
  color: rgb(184, 184, 184);
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  
  background-color: 1d1d1d;
  color: rgb(255, 255, 255);
}

.k-calendar .k-content.k-scrollable, .k-calendar .k-calendar-content.k-scrollable {
  background-color: rgba(0, 0, 0, 0);
  .k-grid-toolbar {
  
  background-color: #292929 !important;
  color: white !important;
}
.k-grid-pager{
  background-color: #292929 !important;
  color: white !important;
}
}`}
        </style>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TimeWtdRtnRptGrid;
