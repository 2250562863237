import * as React from "react";
import { customToken } from "./customToken";

import { useState, useEffect, useMemo } from "react";

import axios from "axios";

import { DatePicker } from "@progress/kendo-react-dateinputs";

import AcctHoldingGrid from "./acctHoldingGrid";

import { Card, FormControlLabel } from "@mui/material";

import { BsFillInfoCircleFill } from "react-icons/bs";

import Loading from "./loading";

import { useLocation, useNavigate } from "react-router-dom";

import { CustomCalendar } from "./customCalendar";

import { MdFilterAltOff } from "react-icons/md";

import { formatNumber, formatDate, numberSymbols } from "@telerik/kendo-intl";

import {
  FaFileExcel,
  FaPlusSquare,
  FaMinusSquare,
  FaSave,
  FaChevronDown,
  FaCog,
  FaInfoCircle,
} from "react-icons/fa";

import { Button as BtnAntd, Dropdown, Menu, Space } from "antd";

import { filterBy, orderBy } from "@progress/kendo-data-query";

import moment from "moment/moment";

import Checkbox from "@mui/material/Checkbox";

import { blue, blueGrey } from "@mui/material/colors";

import { useSelector } from "react-redux";

import { selectTheme } from "../features/theme/themeSlice";

import { message } from "antd";

import { Modal } from "antd";

import { Button } from "@progress/kendo-react-buttons";

import { Viewer } from "@grapecity/activereports-react";

import ModalBtstrp from "react-bootstrap/Modal";

import { FaFilePdf } from "react-icons/fa";

import { Circles, ColorRing } from "react-loader-spinner";

const AcctHoldingRpt = (acctId) => {
  const [expandedState, setExpandedState] = React.useState(true);

  const [totMarketvalue, setTotMarketValue] = React.useState(0.0);

  const [totalCash, setTotalCash] = React.useState(0.0);

  var date = new Date(localStorage.getItem("processingDate"));

  const [asOfDt, setasOfDt] = React.useState(date);

  const [priceDt, setpriceDt] = React.useState(date);

  const [triggerExport, setTriggerExport] = useState(0);

  const [triggerColapse, setTriggerColapse] = useState(0);

  const [triggerExpand, setTriggerExpand] = useState(0);

  const [triggerColSave, setTriggerColSave] = useState(0);

  const [triggerClearfilter, setTriggerClearfilter] = useState(0);

  var minDate = new Date(localStorage.getItem("processingDate"));

  minDate.setMonth(minDate.getMonth() - 60);

  var maxDate = new Date(localStorage.getItem("processingDate"));

  const [AcctHoldingReportData, populateAcctHoldingReportData] = useState([]);

  //maxDate.setMonth(maxDate.getMonth());

  const [minPDt, setminPDt] = React.useState(minDate);

  const [maxPDt, setmaxPDt] = React.useState(maxDate);

  const [AcctHoldingRptData, populateAcctHoldingRptData] = useState([]);

  const [totalLiqPriBal, populatetotalLiqPriBal] = useState(0.0);

  const [incomeCash, populateincomeCash] = useState(0.0);

  const [clearing, setClearing] = useState(false);

  // const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));

  //   const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));

  const [loading, setLoading] = useState(false);

  const [isColumnSave, setIsColumnSave] = useState(false);

  const [effectivecDtInd, seteffectiveDtInd] = useState(true);

  var tempToken = JSON.parse(localStorage.getItem("token"));

  const [session, setSession] = useState("");

  const navigate = useNavigate();

  const viewerRef = React.useRef();

  const [show, setShow] = useState(false);

  const [dataAccountHoldingUpdate, setDataAccountHoldingUpdate] =
    React.useState(AcctHoldingReportData);

  const handleClose = () => {
    setShow(false);
  };

  // function setDate(val) {

  //   if (val == null) {

  //     setasOfDt(asOfDt);

  //   } else {

  //     setasOfDt(val);

  //   }

  // }

  // function setPrcDt(val) {

  //   if (val == null) {

  //     setpriceDt(priceDt);

  //   } else {

  //     setpriceDt(val);

  //   }

  // }

  const bankName = process.env.REACT_APP_BANK;

  // const bankName = "Demo";

  console.log("bankName", bankName);

  async function loadReport() {
    // load report definition from the file

    const reportResponse = await fetch("Holding.rdlx-json");

    const report = await reportResponse.json();

    return report;
  }

  async function openReport() {
    const report = await loadReport();

    // debugger;

    // for(let i=0;i<reportData.t1.length;i++){

    //   reportData.t1[i].mdlWegh = parseNumber(formatNumber( reportData.t1[i].mdlWegh,"n2"));

    // }

    report.ReportSections[0].PageFooter.ReportItems[0].Value =
      bankName + ".png";

    console.log("JSON", AcctHoldingReportData);

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(AcctHoldingReportData);

    let parameters = [
      {
        // Name: "pConsName",

        // Value: [

        //   localStorage.getItem("SelAcctNm") == ""

        //     ? 0

        //     : localStorage.getItem("SelAcctNm"),

        // ],

        Name: "pConsName",

        Value: [
          localStorage.getItem("pConsName") == "" ||
          localStorage.getItem("pConsName") == null
            ? 0
            : localStorage.getItem("pConsName"),
        ],
      },

      {
        Name: "pAccName",

        Value: [
          localStorage.getItem("SelAcctNm") == "" ||
          localStorage.getItem("SelAcctNm") == null
            ? 0
            : localStorage.getItem("SelAcctNm"),
        ],

        // Name: "pConsName",

        // Value: [

        //   localStorage.getItem("pConsName") == ""

        //     ? 0

        //     : localStorage.getItem("pConsName"),

        // ],
      },

      {
        Name: "pShow",

        Value: [
          localStorage.getItem("SelAcctId") == "0" ? 1 : 0,

          // localStorage.getItem("pConsId") == "0"

          //   ? 0

          //   : localStorage.getItem("SelAcctNm"),
        ],

        // Name: "pConsName",

        // Value: [

        //   localStorage.getItem("pConsName") == ""

        //     ? 0

        //     : localStorage.getItem("pConsName"),

        // ],
      },
    ];

    /////////////////////

    // viewerRef.current.Viewer.open(report);

    viewerRef.current.Viewer.open(report, { ReportParams: parameters });
  }

  const handleEffectiveDtInd = (e) => {
    seteffectiveDtInd(e.target.checked ? true : false);
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));

    let UserId = JSON.parse(localStorage.getItem("userId"));

    let GridId = 34;

    const postData = { UserId, GridId };

    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        authorization: `Bearer ${token.token}`,

        Accept: "application/json",

        "Content-Type": "application/json",
      },
    };

    await axios

      .post("/RTGetGridColumn/Index", postData, config)

      .then((response) => {
        // console.log('save in db');

        // console.log(response);
        debugger;
        const rowData = response.data;

        if (rowData !== "") {
          setIsColumnSave(true);

          localStorage.setItem("gridColumnsHolding", rowData);
        } else {
          setIsColumnSave(false);
        }
      })

      .catch((error) => {
        console.log("error in save db " + error);

        return error;
      });
  };

  const excelExport = () => {
    setShow(true);

    openReport();

    // if (_export.current !== null) {

    //   _export.current.save(allData);

    // }
  };

  const GetAcctHoldinData = async () => {
    setLoading(true);

    let token = tempToken;

    // let token = JSON.parse(localStorage.getItem('token'));

    let UserId = JSON.parse(localStorage.getItem("userId"));

    let AcctId = acctId.acctId; //JSON.parse(localStorage.getItem('AcctSelected')).acctId;

    let AsofDt = formatDate(asOfDt, "MM/dd/yyyy");

    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");

    let EffectiveDtInd = effectivecDtInd; //

    let PriceDt = priceDt === null ? "" : formatDate(priceDt, "MM/dd/yyyy");

    let mConsolidationId = localStorage.getItem("pConsId");

    const postData = {
      UserId,

      AcctId,

      AsofDt,

      EffectiveDtInd,

      PriceDt,

      mConsolidationId,

      RltnshpCd,
    };

    debugger;

    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        authorization: `Bearer ${token.token}`,

        Accept: "application/json",

        "Content-Type": "application/json",
      },
    };

    await axios

      .post("/AcctHolding", postData, config)

      .then((response) => {
        debugger;
        response.data.ocAcctHolding =
          response.data.ocAcctHolding === null
            ? []
            : response.data.ocAcctHolding;
        response.data.totalCash =
          response.data.totalCash === null ? 0 : response.data.totalCash;

        const rowData = response.data;

        populateAcctHoldingReportData(rowData);

        for (var i = 0; i < rowData.ocAcctHolding.length; i++) {
          if (moment(rowData.ocAcctHolding[i].holdingDate).isValid())
            rowData.ocAcctHolding[i].holdingDate = formatDate(
              new Date(rowData.ocAcctHolding[i].holdingDate),

              "MM/dd/yyyy"
            );

          if (moment(rowData.ocAcctHolding[i].priceDate).isValid())
            rowData.ocAcctHolding[i].priceDate = formatDate(
              new Date(rowData.ocAcctHolding[i].priceDate),

              "MM/dd/yyyy"
            );
        }

        var resultObjectDistinct = orderBy(rowData.ocAcctHolding, [
          { field: "mjrAstNm", dir: "asc" },

          { field: "mnrAstNm", dir: "asc" },
        ]);

        populateAcctHoldingRptData(rowData.ocAcctHolding);
        handleclearfilter();

        //setTotMarketValue(rowData.ocAcctHolding[0].market+rowData.ocAcctHolding[0].principalCash+rowData.ocAcctHolding[0].incomeCash+rowData.ocAcctHolding[0].investedIncome)

        getColumnStateDb();

        var sum = 0;

        var sumAccuredInc = 0;

        for (var i = 0; i < rowData.ocAcctHolding.length; i++) {
          sum = sum + rowData.ocAcctHolding[i].market;

          sumAccuredInc = sumAccuredInc + rowData.ocAcctHolding[i].acrdIncmAmt;
        }

        // if (rowData.ocAcctHolding.length > 0) {
        //setTotMarketValue(sum + sumAccuredInc + rowData.ocAcctHolding[0].principalCash + rowData.ocAcctHolding[0].incomeCash);

        setTotMarketValue(rowData.ocAcctHolding.length <= 0 ? 0 : sum);
        debugger;
        populatetotalLiqPriBal(
          rowData.ocAcctHolding.length <= 0
            ? 0
            : rowData.ocAcctHolding[0].totalLiqPriBal
        );

        populateincomeCash(
          rowData.ocAcctHolding.length <= 0
            ? 0
            : rowData.ocAcctHolding[0].incomeCash
        );

        setTotalCash(rowData.totalCash);

        setLoading(false);
      })

      .catch((error) => {
        debugger;

        console.log("my error is 1 " + error);

        if (error.response.status === 401) {
          refreshToken();
        }

        return error;
      });
  };

  let userId = JSON.parse(localStorage.getItem("userId"));

  useEffect(() => {
    const fetchData = () => {
      GetAcctHoldinData();

      getColumnStateDb();
    };

    fetchData();
  }, [userId]);

  //new

  const [DialogMsg, setDialogMsg] = useState("");

  const [visible, setVisible] = React.useState(false);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const formSubmit = (e) => {
    e.preventDefault();

    if (asOfDt == null || priceDt == null) {
      setDialogMsg("Enter date in correct format mm/dd/yyyy");

      toggleDialog();

      setasOfDt(new Date(localStorage.getItem("processingDate")));

      setpriceDt(new Date(localStorage.getItem("processingDate")));
    } else {
      const validationRules = [
        {
          condition: asOfDt > maxDate,

          message: "As Of Date cannot be exceed to the Processing Date",
        },

        {
          condition: asOfDt < minDate,

          message:
            "As Of Date cannot be less than 5 years to the Processing Date",
        },

        {
          condition: priceDt > maxDate,

          message: "Price Date cannot be exceed to the Processing Date",
        },

        {
          condition: priceDt < minDate,

          message:
            "Price Date cannot be less than 5 years to the Processing Date",
        },
      ];

      const messages = validationRules

        .filter((rule) => rule.condition)

        .map((rule) => rule.message);

      if (messages.length > 0) {
        setDialogMsg(messages.join("\n"));

        toggleDialog();

        return;
      }

      setTriggerColSave(0);

      GetAcctHoldinData();
      getColumnStateDb();
      setExpandedState(true);
    }
  };

  const handleExcelExport = (event) => {
    setTriggerExport((triggerExport) => triggerExport + 1);
  };

  const handleTriggerColapse = (event) => {
    setTriggerColapse((triggerColapse) => triggerColapse + 1);

    setExpandedState(false);
  };

  const handleTriggerExpand = (event) => {
    setTriggerExpand((triggerExpand) => triggerExpand + 1);

    setExpandedState(true);
  };

  const handleTriggerColSave = (event) => {
    console.log("save called :", triggerColSave);

    setTriggerColSave((triggerColSave) => triggerColSave + 1);
  };

  const handleclearfilter = (event) => {
    setTriggerClearfilter((triggerClearfilter) => triggerClearfilter + 1);

    console.log("clear filter", triggerColSave);

    setClearing(true);

    setTimeout(() => {
      setClearing(false);
    }, 1400);
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));

    let AccessToken = token.token;

    let RefreshToken = token.refreshToken;

    const postData = { AccessToken, RefreshToken };

    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios

      .post("/token/Refresh", postData, config)

      .then((response) => {
        tempToken = response.data;

        localStorage.setItem("token", JSON.stringify(response.data));

        GetAcctHoldinData();
      })

      .catch((error) => {
        //

        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }

        console.log("my error is " + error);
      });
  };

  const signOut = () => {
    navigate("/");

    let token = JSON.parse(localStorage.getItem("token"));

    const postData = {};

    const config = {
      headers: {
        "X-Custom-Header": localStorage.getItem("location"),
        FiSignature: customToken(),

        authorization: `Bearer ${token.token}`,

        Accept: "application/json",

        "Content-Type": "application/json",
      },
    };

    axios

      .post("/token/revoke", postData, config)

      .then((response) => {})

      .catch((error) => {
        //

        console.log("my error is " + error);
      });

    localStorage.removeItem("token");

    localStorage.clear();
  };

  const themes = useSelector(selectTheme);

  const datepattern = "mm/dd/yyyy";

  if (loading) {
    return (
      <div className="container-fluid">
        <Loading />
      </div>
    );
  }

  console.log("minDate", minDate);

  console.log("maxDate", maxDate);

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<FaFilePdf />} onClick={excelExport}>
        pdf
      </Menu.Item>
      <Menu.Item key="2" icon={<FaFileExcel />} onClick={handleExcelExport}>
        excel
      </Menu.Item>
      <Menu.Item key="3" icon={<FaSave />} onClick={handleTriggerColSave}>
        save
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <ModalBtstrp
        style={{ zIndex: 1000000 }}
        show={show}
        onHide={handleClose}
        fullscreen={true}
        size="xl"
      >
        <ModalBtstrp.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </ModalBtstrp.Body>

        <ModalBtstrp.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </ModalBtstrp.Footer>
      </ModalBtstrp>

      <div className="container-fluid">
        <Modal
          title="Validation"
          open={visible}
          onOk={(e) => {
            setVisible(false);
          }}
          onCancel={(e) => {
            setVisible(false);
          }}
          footer={[
            <Button
              key="Ok"
              onClick={(e) => {
                setVisible(false);
              }}
            >
              Ok
            </Button>,
          ]}
          style={{ zIndex: 1000000 }}
        >
          <div style={{ whiteSpace: "pre-line" }}>{DialogMsg}</div>
        </Modal>

        <table
          className="table tablel-bordered border border-bottom border-gray m-0 w-100 bg-show"
          style={{ backgroundColor: themes === "dark" ? "#4f4f4f" : "#d9f3ff" }}
        >
          <tbody>
            <tr>
              <td
                className="align-bottom"
                style={{ color: themes === "dark" ? "white" : "#454545" }}
              >
                Total Liquid Principal Balance :
                <span
                  style={{ color: themes === "dark" ? "#47daff" : "#006fba" }}
                >
                  {" "}
                  ${formatNumber(totalLiqPriBal, "##,#.00")}{" "}
                </span>
              </td>

              <td
                className="align-bottom"
                style={{ color: themes === "dark" ? "white" : "#454545" }}
              >
                Income:
                <span
                  style={{ color: themes === "dark" ? "#47daff" : "#006fba" }}
                >
                  {" "}
                  ${formatNumber(incomeCash, "##,#.00")}
                </span>
              </td>

              <td
                className="align-bottom"
                style={{ color: themes === "dark" ? "white" : "#454545" }}
              >
                {" "}
                <BsFillInfoCircleFill
                  title="Total market value includes accrued income + real time manual asset price adjustments."
                  color={themes === "dark" ? "#47daff" : "#0070cc"}
                  size={15}
                />
                &nbsp; Total Market Value:
                <span
                  style={{ color: themes === "dark" ? "#47daff" : "#006fba" }}
                >
                  {" "}
                  ${formatNumber(totMarketvalue, "##,#.00")}
                </span>
              </td>

              <td
                className="align-bottom"
                style={{ color: themes === "dark" ? "white" : "#454545" }}
              >
                Total Cash:
                <span
                  style={{ color: themes === "dark" ? "#47daff" : "#006fba" }}
                >
                  {" "}
                  ${formatNumber(totalCash, "##,#.00")}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <>
          <table className="table table-borderless m-0 w-100">
            <tbody>
              <tr className="align-middle">
                {/* Collapse/Expand Button */}
                <td
                  className="align-middle text-center"
                  style={{ borderRight: "none" }}
                >
                  {expandedState ? (
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={handleTriggerColapse}
                    >
                      <FaMinusSquare /> Collapse
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={handleTriggerExpand}
                    >
                      <FaPlusSquare /> Expand
                    </button>
                  )}
                </td>

                {/* Table Options Dropdown (Save Columns, Clear Filters) */}
                <td
                  className="align-middle text-center"
                  style={{ borderRight: "none" }}
                >
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={handleTriggerColSave}>
                          <FaSave /> Save Columns
                        </Menu.Item>
                        <Menu.Item onClick={handleclearfilter}>
                          <MdFilterAltOff /> Clear Filters
                        </Menu.Item>
                        <Menu.Item onClick={handleExcelExport}>
                          <FaFileExcel /> Export to Excel
                        </Menu.Item>
                        <Menu.Item onClick={excelExport}>
                          <FaFilePdf /> Export to PDF
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <button className="btn btn-outline-secondary btn-sm">
                      <FaCog /> Table Options <FaChevronDown />
                    </button>
                  </Dropdown>
                </td>

                {/* Date Filters */}
                <td
                  className="align-middle"
                  colSpan="3"
                  style={{ borderRight: "none" }}
                >
                  <div className="d-flex justify-content-start gap-3 align-items-center w-100">
                    <div className="d-flex align-items-center flex-grow-1">
                      <label
                        className="me-2"
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                      >
                        As Of Date:
                      </label>
                      <DatePicker
                        className="form-control form-control-sm"
                        size={"small"}
                        width={150}
                        value={asOfDt}
                        max={maxDate}
                        min={minDate}
                        valid={
                          asOfDt >= minDate &&
                          asOfDt <= maxDate &&
                          asOfDt != null
                        }
                        onChange={(e) => {
                          setasOfDt(e.value);
                          setpriceDt(e.value || priceDt);
                        }}
                      />
                    </div>

                    <div className="d-flex align-items-center flex-grow-1">
                      <label
                        className="me-2"
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                      >
                        Price Date:
                      </label>
                      <DatePicker
                        className="form-control form-control-sm"
                        size={"small"}
                        width={150}
                        value={priceDt}
                        max={maxDate}
                        min={minDate}
                        valid={
                          priceDt >= minDate &&
                          priceDt <= maxDate &&
                          priceDt != null
                        }
                        onChange={(e) => setpriceDt(e.value)}
                      />
                    </div>
                  </div>
                </td>

                {/* Effective Date Indicator Checkbox */}
                <td
                  className="align-middle text-center"
                  style={{
                    color: themes === "dark" ? "white" : "#454545",
                    borderRight: "none",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "0398fc",
                          "&.Mui-checked": {
                            color: "#0398fc",
                          },
                        }}
                        checked={effectivecDtInd}
                        onChange={handleEffectiveDtInd}
                      />
                    }
                    label={
                      <span
                        style={{
                          color: themes === "dark" ? "white" : "#454545",
                        }}
                      >
                        Eff.Date
                        <span
                          className="ms-1"
                          data-bs-toggle="tooltip"
                          title="Effective Date Indicator"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          <FaInfoCircle />
                        </span>
                      </span>
                    }
                  />
                </td>

                {/* Submit Button */}
                <td className="align-middle text-center">
                  <button
                    className="btn btn-primary btn-sm"
                    type="submit"
                    onClick={formSubmit}
                  >
                    Submit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </>

        {/* <div className="accordion" id="accordionExample2">

  <div className="accordion-item">

    <h2 className="accordion-header" id="headingOne">

      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

        Holdings Report

      </button>

    </h2>

    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample2">

      <div className="accordion-body">

     

         <hr></hr>

 

      <form onSubmit={formSubmit}  >

      <div className='row align-items-center'>

     

      <div className='col-md-2'>

        <span className='py-1'>As Of Date</span>

                  <DatePicker id="dpAsOfDt"

                    defaultValue={asOfDt}

                    format="MM/dd/yyyy"

                    calendar={CustomCalendar}

                   

                   

                    formatPlaceholder={{

                      year: "yyyy",

                      month: "mm",

                      day: "dd",

                    }}

                    //formatPlaceholder="datepattern"

                    //placeholder="mm/dd/yyyy"

                    min={minPDt}

                    max={maxPDt}

                    //disabled={isDisabled}

                    onChange={(e) => {

                      setDate(e.value);

 

                    }}

 

                  />

 

        </div>

        <div className='col-md-2'>

        <span className='py-1'>Price Date</span>

                  <DatePicker

                    //disabled={true}

                    title='mm/dd/yyyy'

                    defaultValue={priceDt}

                    format="MM/dd/yyyy"

                    calendar={CustomCalendar}

                    min={minPDt}

                    max={maxPDt}

                    onChange={(e) => {

                      setPrcDt(e.value);

 

                    }}

                    formatPlaceholder={{

                      year: "yyyy",

                      month: "mm",

                      day: "dd",

                    }}

                  />

 

        </div>

 

        <div className='col-md-2'>

        <label className='form-check-label px-2'>Effective Date Indicator</label>

 

<input className='form-check-input' type='checkbox' name='chkInvTrgMix'

  checked={effectivecDtInd} onChange={handleEffectiveDtInd}  ></input>

 

          </div>

          <div className='col-md-2'>

                  <input type="submit" className='btn btn-outline-primary' value="Submit"></input>

 

                </div>

                <div className='col-md-4'></div>

 

   

      </div>




      </form>

 

   

     

     

      </div>

    </div>

  </div>

 

  </div> */}

        <div>
          <div className="row d-flex  justify-content-between">
            <AcctHoldingGrid
              data={AcctHoldingRptData}
              flag={isColumnSave}
              triggerExport={triggerExport}
              triggerColapse={triggerColapse}
              triggerExpand={triggerExpand}
              triggerColSave={triggerColSave}
              triggerClearfilter={triggerClearfilter}
              incomeCash={incomeCash}
              totalLiqPriBal={totalLiqPriBal}
              totMarketvalue={totMarketvalue}
              totalCash={totalCash}
              setExpandedState={setExpandedState}
              populateAcctHoldingReportData={populateAcctHoldingReportData}
              AcctHoldingReportData={AcctHoldingReportData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcctHoldingRpt;
